import React, {useEffect, useState, useRef } from "react";
import styles from "./Settings.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { firestore, functions } from "../../Firebase";
import { sendMail } from "../../Values/utils";
import Loading from "../../Components/Loading/Loading";
import TextInput from "../../Components/TextInput/TextInput";
import Label from "../../Components/Label";
import { FaUser } from "react-icons/fa";
import { updateAdminSettings } from "../../Services/organizations";
import { googleAnalytics } from "../../Services/users";
import {emailValidate,emailValidator} from "../../Values/validatorsnew"

const OrgansationSettting = ({ theme, organization, organizationId,user }: any) => {
  // const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();

  const containerStyles = {
    backgroundColor: themeColors.backgroundColor
  };
  const cardContainerStyles = {
    backgroundColor: themeColors.cardBackgroundColor
  };

  const [load, setLoad] = useState(false);
  const verificationMailId: any = useRef();
  const communicationMailId: any = useRef();
  const [isGACalled, setIsGACalled] = useState<any>(false);
  const [OTPVerify, setOTPVerify] = useState(organization.otp_verification_before_export);
  
  useEffect(()=>{
    setOTPVerify(organization.otp_verification_before_export)
  },[organization])

  const submit = (e: any) => {
    e.preventDefault();
    setLoad(true);
    updateAdminSettings(organizationId,OTPVerify,verificationMailId.current.value,communicationMailId.current.value, setLoad, dispatcher);
    // console.log("amzmzmzzjzj",organization,organizationId);
  }

  useEffect(()=>{
    if(isGACalled === false){
      if(user?.uid && user?.profile){
        setIsGACalled(true)
        googleAnalytics('Admin_Setting_Screen',user)
      }
    }
  },[user])
  return (
    <div className={styles.parent}  style={containerStyles} >
      {load === true && <Loading />}
      <div className="d-flex flex-row w-100 px-4 pt-4">
        <h5>{" "}{"Settings"}</h5>
      </div>
      <div className="w-100 d-flex flex-column gap-2 px-4">
        <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
          <h6>OTP Verification Email</h6>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <div style={{ fontSize: "14px" }}>{"Send OTP before exporting/deleting leads"}</div>
              <input 
                style={{ width: 100, alignSelf: "center" }} 
                type="checkbox" 
                checked={OTPVerify}
                onChange={() => {setOTPVerify(!OTPVerify)}} />
            </div>
            <div className={styles.divide}>
              <Label label="Enter Email Id" required={false} />
              <div className={styles.inputContainer}>
                <TextInput 
                title={""}
                style={{ backgroundColor: "#fff" }}
                ref={verificationMailId}
                disabled={false}
                value={
                  organization.otp_verification_mail ? organization.otp_verification_mail : ""
                }
                validator={emailValidator}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Communication Email" required={false} />
              <div className={styles.inputContainer}>
                <TextInput 
                title={""}
                style={{ backgroundColor: "#fff" }}
                ref={communicationMailId}
                disabled={false}
                value={
                  organization.communication_mail ? organization.communication_mail : ""
                }
                validator={emailValidator}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            {/* <div className={styles.divide}>
              <Label label="Enter Email Id" required={true} />
              <div className={styles.inputContainer}>
                <TextInput title={""} style={{ backgroundColor: "#fff" }}>
                  <FaUser color={"#808080"} />
                </TextInput> 
              </div>
            </div> */}

          </div>
          <button type="submit" style={{ backgroundColor: "#279f9f" }} className={styles.button} onClick={(e: any) => {
            submit(e);
          }}>
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    user: state.user.data,
    authToken: state.user.authToken,
    organization: state.organization,
    organizationId: state.organization.id,
  };
};

// export default OrgansationSettting
export default connect(mapStateToProps)(OrgansationSettting)
