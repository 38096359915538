import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styles from "./Import.module.css";
import { CSVLink } from "react-csv";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import axios from "axios";
import { url } from "../../Values/constants";
// import PopUpBox from "../../Screens/AuditLogs/PopUpBox";
import axiosHandler from "../../Services/axiosHandler";


type props = {
  onClick?: () => void;
  setExpandImport: (data: boolean) => void;
  onExport:any;
  onCallLogsImport?: () => any;
  onNotesImport?: () => any;
  onTasksImport?: () => any;
  title?: any;
  expand: boolean;
  close: () => void;
  user: any;
  role: any;
  theme: any;
  selectedRows?: any[];
  setAuditLog?: any;
  otp?:any;
  isOtpVerified?:any;
  organizationData?:any;
  panel?:any
  userPreference?:any;
  setListView?: (data: boolean) => void;
  setAuthorizationView?: (data: boolean) => void;
};

const Import: FunctionComponent<props> = ({
  onClick,
  setExpandImport,
  onExport,
  onNotesImport,
  onCallLogsImport,
  onTasksImport,
  title,
  expand,
  close,
  role,
  user,
  theme,
  selectedRows,
  setAuditLog,
  otp,
  isOtpVerified,
  organizationData,
  panel,
  userPreference,
  setListView,
  setAuthorizationView
}) => {
  const [popUpBox, setPopUpBox] = useState(false);
  const [dateTime, setDateTime] = useState('');

  const SaveData = async () => {
    csvLink.current.link.click();
    try {
      let res = await axiosHandler.post(url+'/auditLogs/createExportLogs', {
          uid:user.uid?user.uid:"",
          user_email:user.user_email?user.user_email:"",
          user_first_name:user.user_first_name?user.user_first_name:"",
          user_last_name:user.user_last_name?user.user_last_name:"",
          total_count:selectedRows?.length?selectedRows?.length:"",
          type:panel?panel:"",
          organization_id:user.organization_id?user.organization_id:"",
          operation_type:"Export",
          description:`${user.user_first_name?user.user_first_name:""} ${user.user_last_name?user.user_last_name:""} with user email ${user.user_email?user.user_email:""} exported ${selectedRows?.length?selectedRows?.length:""} records from ${panel?panel:""} panel.`
      })
      // console.log("Export Log Saved Successfully");
    } catch (error) {
      // console.log(error,"Export Log Couldn't Be Saved");
    }
}

const handleExport = () => {
  if (feilds.length !== 0) {
    if (!organizationData.otp_verification_before_export) {
      SaveData();
    } else {
      SendOTP();
      setAuditLog(true);
    }
  } else {
    dispatcher(
      showSnackbarAction(
        "Please Select The Records To Export",
        "error"
      )
    );
  }
};

const checkUserPermissionBeforeExport = () => {
  if (
    (panel === "contacts" || panel === "contacts drilldown") &&
    userPreference.contact_export_approved === false
  ) {
    dispatcher(
      showSnackbarAction(
        "You are not allowed to export leads. Please contact your admin",
        "error"
      )
    );
  } else if (
    (panel === "tasks" || panel === "tasks drilldown") &&
    userPreference.task_export_approved === false
  ) {
    dispatcher(
      showSnackbarAction(
        "You are not allowed to export tasks. Please contact your admin",
        "error"
      )
    );
  } else if (
    (panel === "call logs" || panel === "call logs drilldown") &&
    userPreference.contact_export_approved === false
  ) {
    dispatcher(
      showSnackbarAction(
        "You are not allowed to export call logs. Please contact your admin",
        "error"
      )
    );
  } else if (
    panel === "projects" &&
    userPreference.project_export_approved === false
  ) {
    dispatcher(
      showSnackbarAction(
        "You are not allowed to export projects. Please contact your admin",
        "error"
      )
    );
  } else if (
    panel === "api leads" &&
    userPreference.api_export_approved === false
  ) {
    dispatcher(
      showSnackbarAction(
        "You are not allowed to export api leads. Please contact your admin",
        "error"
      )
    );
  } else {
    handleExport();
  }
};

const checkUserPermissionBeforeImport = () => {
  panel === "projects" && userPreference.project_import_approved === false
    ? dispatcher(
        showSnackbarAction(
          "You are not allowed to import projects. Please contact your admin",
          "error"
        )
      )
    : panel === "contacts" && userPreference.contact_import_approved === false
    ? dispatcher(
        showSnackbarAction(
          "You are not allowed to import leads. Please contact your admin",
          "error"
        )
      )
    : onClick && onClick();
  setExpandImport(false);
};

const SendOTP = async () => {
  console.log("selected rows",selectedRows);
  try {
    let res = await axiosHandler.post(url+'/otpVerification/sendOtpNew', {
      uid:user.uid?user.uid:"",
      user_email:user.user_email?user.user_email:"",
      user_first_name:user.user_first_name?user.user_first_name:"",
      user_last_name:user.user_last_name?user.user_last_name:"",
      otp_mail:organizationData.otp_verification_mail ? organizationData.otp_verification_mail : "",
      data_count:selectedRows?.length?selectedRows?.length:"",
      type:panel?panel:"",

    })
    dispatcher(
      showSnackbarAction(
        res.data.message,
        'success'
      )
    );
  } catch (error) {
    dispatcher(
      showSnackbarAction(
        "OTP Not Sent ! Please Try Again",
        'error'
      )
    );
  }
};
  const csvLink: any = useRef();
  const modalRef = useRef(null);
  const filterClass = classNames(styles.parent, {
    [styles.showAnim]: expand === true,
  });
  const [feilds, setFields] = useState([]);
  const dispatcher = useDispatch();

  useEffect(() => {
    // setFields(onExport && onExport());
    const setFieldsData = async () => {
      let data = await onExport();
      setFields(data);
    }
    setFieldsData()
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = useCallback((event: any) => {
    const element: any = modalRef.current;
    if (element && !element.contains(event.target)) {
      close();
    }
    // eslint-disable-next-line
  }, []);
  const onSubmit = () => { };

  useEffect(() => {
    if (expand === true) {
      setTimeout(
        () => document.addEventListener("click", handleClickOutside),
        100
      );
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [expand]);

  const cardContainerStyles = { backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div
      className={filterClass}
      ref={modalRef}
      style={user.import !== true ?( panel == 'contacts' || panel == 'projects' )?{ height: "100px", ...cardContainerStyles }: (panel == 'tasks' || panel == 'call logs' || panel == 'api leads' || title === "Drill Down")?{ height: "60px", ...cardContainerStyles }:{ height: "150px",marginRight:'80px', ...cardContainerStyles } : cardContainerStyles}
    >
      {role !== "Team Lead" ?
        <>
          <div>
            <div
            // style={{marginTop:"10px"}}
              onClick={() => {
                checkUserPermissionBeforeExport();
              }}
            >
            <p
              className={styles.importText}
            >Export</p>
            {/* <CSVLink
              data={feilds}
              filename={"data.csv"}
              className={styles.importText}
              ref={csvLink}
              target="_blank"
            /> */}
          </div>
          {/* <div className={styles.line}></div> */}
        </div>
    </> : ""
}
{
  title !== "Drill Down" && (
    <div style={{textAlign:"center"}}>
      {/* {role !== 'organization' &&   */}
      <p className={styles.importText}
      onClick={() => {
        checkUserPermissionBeforeImport();
      }}
    >
      {/* {role === "Lead Manager" || role === "Team Lead"
        ? (panel == 'contacts' || panel == 'projects' ) && "Import":"import users"
        } */}
          {((role === "Lead Manager" || role === "Team Lead" || user.profile === "Admin") && (panel == 'contacts' || panel == 'projects' ))
          && "Import"
        }
    </p>
    {/* // } */}
    <p
      className={styles.importText}
      // style={{marginTop:"-30px"}}
      onClick={() => {
        setListView && setListView(true);
      }}
    >
      {(user.profile === 'Admin') && (panel === 'User Management')
         && "Set List View"
        }
    </p>
    <p
      className={styles.importText}
      onClick={() => {
        setAuthorizationView && setAuthorizationView(true);
      }}
    >
      {(user.profile === 'Admin') && (panel === 'User Management')
         && "Set User Permissions"
        }
    </p>
    </div>
  )
}
{
  user.import === true && (
    <>
      {onCallLogsImport && (
        <>
          {/* <div className={styles.line}></div> */}

          <p
            className={styles.importText}
            onClick={() => {
              setExpandImport(false);
              onCallLogsImport && onCallLogsImport();
            }}
          >
            Import Call Logs
          </p>
        </>
      )}

      {onNotesImport && (
        <>
          {/* <div className={styles.line}></div> */}
          <p
            className={styles.importText}
            onClick={() => {
              onNotesImport && onNotesImport();
              setExpandImport(false);
            }}
          >
            Import Notes
          </p>
        </>
      )}

      {onTasksImport && (
        <>
          {/* <div className={styles.line}></div> */}
          <p
            className={styles.importText}
            onClick={() => {
              onTasksImport && onTasksImport();
              setExpandImport(false);
            }}
          >
            Import Tasks
          </p>
        </>
      )}
    </>
  )
}
            <CSVLink
              data={feilds}
              filename={"data.csv"}
              className={styles.importText}
              ref={csvLink}
              target="_blank"
            />
    </div >
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    theme: state.theme.isLightMode,
    organizationData: state.organization.organizationData,
    userPreference:state.userPreference.data,
  };
};

export default connect(mapStateToProps)(Import);
