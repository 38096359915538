import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./DistributionModel.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import Firebase from "firebase/app";

import Dropdown from "../../DropDown/Dropdown";
import {
  contactResources,
  CreateLeadDistribution,
  editLeadDistribution,
  fetchConstants,
  fetchLeadDistributionsEdit,
  fetchpropertySubType,
  createLeadDistributionNew,
  editLeadDistributionNew,
  deleteLeadDistributionNew,
  contactResourcesMongo
} from "../../../Services/contacts";
import { useDispatch, connect } from "react-redux";
import Loading from "../../Loading/Loading";

import { showSnackbarAction } from "../../../Redux/actions";
import { themeColors } from "../../theme";
import Label from "../../Label";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import TextInput from "../../TextInput/TextInput";
import AddResources from "../../../Components/Modals/AddResourcesModal/AddResourceModal";
import { BsPlus } from "react-icons/bs";

type props = {
  organization_id: string;
  close: () => void;
  open: boolean;
  organizationUsers: any;
  data?: any;
  index?: number;
  theme: any;
  organizationData: any;
  user1?: any;
  authToken?: any;
  constants?:any;
  userPreference?: any;
  organizationResources:any;
};

const DistributionModel: FunctionComponent<props> = (
  {
    organization_id,
    close,
    open,
    organizationData,
    organizationUsers,
    data,
    index,
    theme,
    user1,
    authToken,
    constants,
    userPreference,
    organizationResources
  },
  { history, user }: { history: any; user: any }
) => {
  const [projectSelected, setProjectSelected] = useState<any>([]);

  const [locationSelected, setlocationSelected] = useState<any>([]);
  const [budgetSelected, setBudgetSelected] = useState<any>([]);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>([]);
  const [formNameSelected, setFormNameSelected] = useState<any>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [sourceSelected, setSourceSelected] = useState<any>([]);
  const [associateSelected, setAssociateSelected] = useState<any>([]);
  const [requirementSelected, setRequirementSelected] = useState<any>([]);

  const dispatcher = useDispatch();
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [formNamesList, setFormNamesList] = useState<any[]>([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [associateList, setAssociateList] = useState<any[]>([]);
  const [uid, setUids] = useState<any>({});
  const [industrialTypes, setIndustrialTypes] = useState<any[]>([]);
  const [institutionalTypes, setInstitutionalTypes] = useState<any[]>([]);
  const [Id, setId] = useState<any>(undefined);
  const [data1, setData1] = useState<any>({});
  const [toggleAutoLeadRotation, setToggleAutoLeadRotation] = useState("OFF");
  const timeRef: any = useRef();
  const [leadAssigne, setLeadAssigne] = useState<any>([]);
  const [leadManagerList, setLeadManagerList] = useState<any[]>([]);
  const [leadRotationTime, setLeadRotationTime] = useState('');
  const [openLeadSource, setOpenLeadSource] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openBudget, setOpenBudget] = useState(false);
  let panel = ""
  const Requirement=[
    "NEW PROJECT",
    "RENTAL",
    "RESALE"
  ];
  useEffect(() => {
  }, [user1, authToken])
  useEffect(() => {
    let data: any = [];
    let leadManager: any = [];
    let uids: { [key: string]: string } = {};
    organizationUsers.forEach((item: any, index: number) => {
      data.push(
        `${item.user_first_name} ${item.user_last_name} -${item.user_email}`
      );
      uids[item.user_email] = item.uid;
      if(item.profile === 'Lead Manager' || item.profile === 'Admin'){
        leadManager.push(`${item.user_first_name} ${item.user_last_name} -${item.user_email}`)
      }
    });

    setUids(uids);
    setAssociateList(data.sort());
    setLeadManagerList(leadManager.sort())
  }, [organizationUsers]);

  useEffect(() => {
    if (data) {
      setId(data._id)
      // if (organization_id) {
      //   fetchLeadDistributionsEdit(organization_id, (data) =>
      //     setFilterData(data)
      //   );
      // }


      // for (const key in data) {
      //   if (data[key].length > 0 && key !== "created_at" && key !== "modify_at") {
      //     if (Array.isArray(data[key])) {
      //       data[key] = data[key];
      //     }else{
      //       data[key] = data[key].split(",    ");
      //     }
      //   }else if( key == "created_at" || key == "modify_at"){
      //     data[key] = data[key];
      //   }else{
      //     data[key] = [];
      //   }
      // }
      // for (const key in data) {
      //   if (key === "budget" || key === "api_forms" || key === "users" || key === "source" || key === "property_type" || key === "location" || key === "project") {
      //     if (typeof data[key] === 'string' && data[key] !== "" && data[key] !== undefined) {
      //       let temp = data[key].split(',').map((item: any) => item.trim());
      //       data[key] = temp.filter((item: any) => item !== '')
      //     } else if (data[key] === "") {
      //       data[key] = []
      //     }
      //   }else if( key == "created_at" || key == "modify_at"){
      //     data[key] = data[key];
      //   }else{
      //     data[key] = [];
      //   }
      // }
      for (const key in data) {
        console.log("key",key)
        if (key === "budget" || key === "api_forms" || key === "users" || key === "source" || key === "property_type" || key === "location" || key === "project" || key ==="requirement_type") {
          if (typeof data[key] === 'string' && data[key] !== "" && data[key] !== undefined) {
            let temp = data[key].split(',').map((item: any) => item.trim());
            data[key] = temp.filter((item: any) => item !== '')
          } else if (data[key] === "") {
            data[key] = []
          }
        }
      }

      // console.log("dtaaa", data)
      let project = data.project.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let location = data.location.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let budget = data.budget.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let property_type = data.property_type.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let api_forms = data.api_forms?.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let source = data.source.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let req = data.requirement_type?.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      let users = data.users.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
            value: item,
            label: "Team Lead",
          }
          : { value: item, label: item }
      );
      setlocationSelected(location);
      setProjectSelected(project);
      setBudgetSelected(budget);
      setPropertyTypeSelected(property_type);
      setRequirementSelected(req);
      setSourceSelected(source);
      setAssociateSelected(users);
      setFormNameSelected(api_forms)
      setToggleAutoLeadRotation(data.autoRotationEnabled)
      setLeadRotationTime(data.autoRotationTime)
      setLeadAssigne({ value: data.returnLeadTo, label: data.returnLeadTo })
    }
  }, [data]);

  const constant = () => {
    const propertyTypeValues = Object.values(constants.propertyType);
    setPropertyTypeList(propertyTypeValues)
    const notIntReasonValues = Object.values(constants.notInterestedReason);
    const lostReasonValues = Object.values(constants.lostReasons);
    setNotIntReasonList(notIntReasonValues);
    setLostReasonList(lostReasonValues);
  };
  useEffect(() => {
    if (constants) {
      constant();
    }
  }, [constants]);

  useEffect(()=>{
    organizationResources.forEach((val:any)=>{
      if(val.resource_type==="locations"){
        let loc:any=[]
        val["locations"].forEach((item:any)=>{
              loc.push(item.location)
        })
        setLocationsList(loc)
      }
      if(val.resource_type==="budgets"){
        let loc:any=[]
        val["budgets"].forEach((item:any)=>{
              loc.push(item.budget)
        })
        setBudgetsList(loc);
      }

      if(val.resource_type==="apiForms"){
        let loc:any=[]
        val["apiForms"].forEach((item:any)=>{
              loc.push(item.apiForm)
        })
        setFormNamesList(loc);
      }
      if(val.resource_type==="leadSources"){
        let loc:any=[]
        val["leadSources"].forEach((item:any)=>{
              if(item.leadSource !== "Self Generated"){
                loc.push(item.leadSource)   
              } 
        })
        setLeadSourceList(loc);
      }

      if(val.resource_type==="comTypes"){
        let loc:any=[]
        val["comTypes"].forEach((item:any)=>{
              loc.push(item.comType)
        })
        setComTypes(loc);
      }
      if(val.resource_type==="resTypes"){
        let loc:any=[]
        val["resTypes"].forEach((item:any)=>{
              loc.push(item.resType)
        })
        setResTypes(loc);
      }
      if(val.resource_type==="industrialTypes"){
        let loc:any=[]
        val["industrialTypes"].forEach((item:any)=>{
              loc.push(item.industrialType)
        })
        setIndustrialTypes(loc);
      }
      if(val.resource_type==="institutionalTypes"){
        let loc:any=[]
        val["institutionalTypes"].forEach((item:any)=>{
              loc.push(item.institutionalType)
        })
        setInstitutionalTypes(loc);
      }
      
    })
  },[organizationResources])
  console.log("setLeadSourceList",sourceSelected)
  useEffect(() => {
    // const unsub = contactResources(
    //   organization_id,
    //   (data) => setLocationsList(data),
    //   (data) => setBudgetsList(data),
    //   () => { },
    //   () => { },
    //   organizationData,
    //   (data) => setFormNamesList(data)
    // );
    // const unsubConst = fetchConstants(
    //   (data) => setNotIntReasonList(data),
    //   (data) => setLostReasonList(data),
      // (data) => setPropertyStageList(data),
      // (data) => setPropertyTypeList(data)
    // );
    // const unsubProperty = fetchpropertySubType(
    //   organization_id,
    //   (data) => setComTypes(data),
    //   (data) => setResTypes(data),
    //   (data) => setLeadSourceList(data),
    //   (data) => setIndustrialTypes(data),
    //   (data) => setInstitutionalTypes(data)
    // );
    // return () => {
    //   // unsub();
    //   unsubConst();
    //   // unsubProperty();
    // };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    contactResourcesMongo(
      organization_id,
      (data) => setProjectsList(data),
      () => {},
      organizationData,
    )
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let requirement: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let apiForms: any = [];
    let userUid
    projectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    sourceSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        source.push(item.value);
      }
    });
    requirementSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        requirement.push(item.value);
      }else{
        requirement.push("NEW PROJECT")
      }
    });
    locationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    budgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    propertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    formNameSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        apiForms.push(item.value);
      }
    });
    associateSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        let l = item.value.split("-");
        let email = l[1];
        usersList.push(uid[email]);
      }
    });
    if(leadAssigne.length !== 0){
      let l = leadAssigne.value.split("-");
        let email = l[1];
        userUid = uid[email]
    }
    if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select A User", "error"));
    } else if (
      source.length === 0 &&
      project.length === 0 &&
      location.length === 0 &&
      budget.length === 0 &&
      propertyType.length === 0 &&
      apiForms.length === 0 &&
      requirement.length ===0
    ) {
      dispatcher(showSnackbarAction("Please Select A Value", "error"));
    } 
      else if(toggleAutoLeadRotation === "ON" && (timeRef.current === undefined || timeRef.current.value.length === 0)){
        dispatcher(showSnackbarAction("Please enter rotation Time (in mins)", "error"));
      }
      else if(toggleAutoLeadRotation === 'ON' && (leadAssigne.length === 0)){
        dispatcher(showSnackbarAction("Please select a lead assignee", "error"));
      }
    else {

      // CreateLeadDistribution(
      //   organization_id,
      //   source,
      //   project,
      //   location,
      //   budget,
      //   propertyType,
      //   usersList,
      //   dispatcher,
      //   (data) => setLoad(data),
      //   close,
      //   apiForms
      // );
      setLoad(true)
      createLeadDistributionNew(
        organization_id,
        source,
        project,
        location,
        budget,
        propertyType,
        usersList,
        requirement,
        dispatcher,
        (data) => setLoad(data),
        close,
        apiForms,
        authToken,
        toggleAutoLeadRotation,
        timeRef.current?timeRef.current.value:undefined,
        userUid
      )
    }
  };

  const onEdit = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let requirement: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let apiForms: any = [];
    let userUid;

    projectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    sourceSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        source.push(item.value);
      }
    });
    locationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    requirementSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        requirement.push(item.value);
      }else{
        requirement.push("NEW PROJECT")
      }
    });
    budgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    propertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    formNameSelected?.forEach((item: any) => {
      if (item.value !== "Select") {
        apiForms.push(item.value);
      }
    });
    associateSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        let l = item.value.split("-");

        let email = l[1];
        if (l[1] === undefined) {
          usersList.push(uid[item.value]);
        } else {
          usersList.push(uid[email]);
        }
      }
    });

if(leadAssigne){
  if (leadAssigne.value !== "Select") {
    let l = leadAssigne.value.split("-");
    let email = l[1];
    if (l[1] === undefined) {
      userUid = uid[leadAssigne.value]
    } else {
      userUid = uid[email]
    }
  }
}

    usersList = Array.from(new Set(usersList));


    if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select A User", "error"));
    } else if (
      source.length === 0 &&
      project.length === 0 &&
      location.length === 0 &&
      budget.length === 0 &&
      propertyType.length === 0 &&
      apiForms.length === 0 &&
      requirement.length ===0
    ) {
      dispatcher(showSnackbarAction("Please Select A Value", "error"));
    } 
    else if(toggleAutoLeadRotation === "ON" && (timeRef.current === undefined || timeRef.current.value.length === 0)){
      dispatcher(showSnackbarAction("Please enter rotation Time (in mins)", "error"));
    }
    else if(toggleAutoLeadRotation === "ON" && (leadAssigne.value === '')){
      dispatcher(showSnackbarAction("Please select a lead assignee", "error"));
    }
    else {
      setLoad(true);

      // if (index !== undefined) {
      //   filterData[index] = {
      //     source: source,
      //     project: project,
      //     location: location,
      //     budget: budget,
      //     property_type: propertyType,
      //     api_forms:apiForms,
      //     users: usersList,
      //     modify_at: Firebase.firestore.Timestamp.now(),
      //   };
      //   editLeadDistribution(
      //     organization_id,
      //     filterData,
      //     dispatcher,
      //     (data) => setLoad(data),
      //     close
      //   );
      // }

      editLeadDistributionNew(
        Id,
        organization_id,
        source,
        project,
        location,
        budget,
        propertyType,
        usersList,
        requirement,
        dispatcher,
        (data) => setLoad(data),
        close,
        apiForms,
        authToken,
        toggleAutoLeadRotation,
        timeRef.current?timeRef.current.value:undefined,
        userUid
      )
    }
  };

  const deleteLogic = () => {
    // if (index !== undefined) {
    //   const newList = [...filterData];
    //   newList.splice(index, 1);
    //   setFilterData(newList);
    //   editLeadDistribution(
    //     organization_id,
    //     newList,
    //     dispatcher,
    //     (data) => setLoad(data),
    //     close,
    //     user1,
    //     panel="Lead Distribution",
    //     authToken
    //   );
    // }
    setLoad(true)
    if (Id !== undefined) {
      deleteLeadDistributionNew(Id,
        dispatcher,
        (data) => setLoad(data),
        close,
        authToken
      );
    }

  };

  const handleChange = (event:any) => {
    setToggleAutoLeadRotation(event.target.checked === true?"ON":"OFF");
  };


  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 22,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#279f9f',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 18,
      height: 18,
      borderRadius: 16,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  console.log("sourceSelected",sourceSelected)
  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
      style={customStyles}
    >
      {load === true &&
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <Loading />
        </div>
      }
      <div className={styles.firstDiv}>
        <h6
        // className={styles.contactForm}
        >
          {data ? "Edit Distribution Logic" : "Distribution Logic"}
        </h6>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      {/* <div className={styles.line}></div> */}

      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Source</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Source" required={false} />
        {/* <div style={{width:"250px"}}> */}
  <div style={{ marginTop: "7px", width: "225px", color: "#000", display: 'flex', alignItems: 'center' }}>
          <Dropdown
            isMulti
            option={leadSourceList}
            selectedValue={sourceSelected}
            setSelectedValue={(value) => {
              setSourceSelected(value);
            }}
          // style={{width:"175px"}}
          />
          <button
              className={styles.addBox}
              onClick={() => setOpenLeadSource(true)}
              style={{ marginLeft: '10px' }}
            >
              <BsPlus size={22} color={"#ffffff"} />
              {/* {title} */}
            </button>
        {/* </div> */}

        
        </div>
      </div>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Project</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Project" required={false} />
        <div style={{ marginTop: "7px", width: "175px", color: "#000", display: 'flex', alignItems: 'center',marginRight:"50px" }}>
          <Dropdown
            isMulti
            option={projectsList}
            selectedValue={projectSelected}
            setSelectedValue={(value) => {
              setProjectSelected(value);
            }}
          />
        </div>
      </div>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Location</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Location" required={false} />
  <div style={{ marginTop: "7px", width: "225px", color: "#000", display: 'flex', alignItems: 'center' }}>
          <Dropdown
            isMulti
            option={locationsList}
            selectedValue={locationSelected}
            setSelectedValue={(value) => {
              setlocationSelected(value);
            }}
          />
          <button
              className={styles.addBox}
              onClick={() => setOpenLocation(true)}
              style={{ marginLeft: '10px' }}
            >
              <BsPlus size={22} color={"#ffffff"} />
              {/* {title} */}
            </button>
        </div>
      </div>

      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Budget</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Budget" required={false} />
  <div style={{ marginTop: "7px", width: "225px", color: "#000", display: 'flex', alignItems: 'center' }}>
          <Dropdown
            isMulti
            option={budgetsList}
            selectedValue={budgetSelected}
            setSelectedValue={(value) => {
              setBudgetSelected(value);
            }}
          />
          <button
              className={styles.addBox}
              onClick={() => setOpenBudget(true)}
              style={{ marginLeft: '10px' }}
            >
              <BsPlus size={22} color={"#ffffff"} />
              {/* {title} */}
            </button>
        </div>
      </div>

      <div className={styles.divide}>
  <Label label="API Form" required={false} />
  <div style={{ marginTop: "7px", width: "225px", color: "#000", display: 'flex', alignItems: 'center' }}>
    <Dropdown
      isMulti
      option={formNamesList}
      selectedValue={formNameSelected}
      setSelectedValue={(value) => {
        setFormNameSelected(value);
      }}
    />
    <button
      className={styles.addBox}
      onClick={() => setOpenForm(true)}
      style={{ marginLeft: '10px' }}
    >
      <BsPlus size={22} color={"#ffffff"} />
      {/* {title} */}
    </button>
  </div>
</div>

      {(!organizationData.business_domain || organizationData.business_domain == "Real Estate") &&
        <div className={styles.divide}>
          <Label label="Property Type" required={false} />
          <div style={{ marginTop: "7px", width: "175px", color: "#000", display: 'flex', alignItems: 'center',marginRight:"50px" }}>
            <Dropdown
              isMulti
              option={propertyTypeList}
              selectedValue={propertyTypeSelected}
              setSelectedValue={(value) => {
                setPropertyTypeSelected(value);
              }}
            />
          </div>
        </div>}
        {(!organizationData.business_domain || organizationData.business_domain == "Real Estate") &&
        <div className={styles.divide}>
          <Label label="Requirement Type" required={false} />
          <div style={{ marginTop: "7px", width: "175px", color: "#000", display: 'flex', alignItems: 'center',marginRight:"50px" }}>
            <Dropdown
              isMulti
              option={Requirement}
              selectedValue={requirementSelected}
              setSelectedValue={(value) => {
                setRequirementSelected(value);
              }}
            />
          </div>
        </div>}
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Associate</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Associate" required={true} />
        <div style={{ marginTop: "7px", width: "175px", color: "#000", display: 'flex', alignItems: 'center',marginRight:"50px" }}>
          <Dropdown
            isMulti
            option={associateList}
            selectedValue={associateSelected}
            setSelectedValue={(value) => {
              setAssociateSelected(value);
            }}
          />
        </div>
      </div>

      <div className={styles.divide}>
        <Label label="Auto Lead Rotation" required={false} />
        <div style={{ marginTop: "7px",minWidth:"175px",color:"#000"}}>
        <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch  inputProps={{ 'aria-label': 'ant design'}} checked={toggleAutoLeadRotation === "ON"?true:false}  onChange={handleChange}/>
      </Stack>
        </div>
      </div>


         {toggleAutoLeadRotation === "ON" && (
          <>
         <div className={styles.divide} style={{marginTop: "7px"}}>
          <Label label="Rotation Time (in mins)" required={true}/>
         <div style={{minWidth:"175px",color:"#000",width:'0'}}>
         <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  type="number"
                  // validator={emailValidate}
                  ref={timeRef}
                  value={leadRotationTime}
                  minValue={5}
                />
         </div>
       </div>
         <div className={styles.divide} style={{marginTop: "7px"}}>
          <Label label="If not attended, assign lead to" required={true}/>
         <div style={{minWidth:"175px",color:"#000"}}>
           <Dropdown
             isMulti={false}
             option={leadManagerList}
             selectedValue={leadAssigne}
             setSelectedValue={(value) => {
              setLeadAssigne(value);
             }}
           />
         </div>
       </div>
       </>
         )}

      {data ? (
        <div className={styles.buttonsBox}>
          <button
            className={styles.delete}
            onClick={() => {
              if (userPreference.lead_distribution_delete_approved === false) {
                dispatcher(
                  showSnackbarAction(
                    "You are not allowed to delete distribution logic. Please contact your admin",
                    "error"
                  )
                );
              } else {
                deleteLogic();
              }
            }}
          >
            Delete
          </button>
          <button
            className={styles.apply}
            onClick={() => {
              if (userPreference.lead_distribution_update_approved === false) {
                dispatcher(
                  showSnackbarAction(
                    "You are not allowed to edit distribution logic. Please contact your admin",
                    "error"
                  )
                );
              } else {
                onEdit();
              }
            }}
          >
            Save
          </button>
        </div>
      ) : (
        <div className={styles.buttonsBox1}>
          <button
            className={styles.apply1}
            onClick={() => {
              if (userPreference.lead_distribution_create_approved === false) {
                dispatcher(
                  showSnackbarAction(
                    "You are not allowed to create distribution logic. Please contact your admin",
                    "error"
                  )
                );
              } else {
                onSubmit();
              }
            }}
          >
            Apply
          </button>
        </div>
      )}
      {openLeadSource && (
          <AddResources
            open={openLeadSource}
            close={() => setOpenLeadSource(false)}
            heading={"Lead Source"}
            title={"Enter Lead Source"}
            type="leadSources"
            subType="leadSource"
            dispatcher={dispatcher}
            setLoad={(value:any) => setLoading(value)}
            setdata={(val:any) => setSourceSelected(val)}
            reload={false}
          />
        )}
        {openLocation && (
          <AddResources
            open={openLocation}
            close={() => setOpenLocation(false)}
            heading={"Location"}
            title={"Enter Location"}
            type="locations"
            subType="location"
            dispatcher={dispatcher}
            setLoad={(value) => setLoading(value)}
            setdata={(val:any) => setlocationSelected(val)}
            reload={false}
          />
        )}
        {openBudget && (
          <AddResources
            open={openBudget}
            close={() => setOpenBudget(false)}
            heading={"Budget"}
            title={"Enter Budget"}
            type="budgets"
            subType="budget"
            dispatcher={dispatcher}
            setLoad={(value) => setLoading(value)}
            setdata={(val:any) => setBudgetSelected(val)}
            reload={false}
          />
        )}
        {openForm && (
          <AddResources
            open={openForm}
            close={() => setOpenForm(false)}
            heading={"API Form"}
            title={"Enter API Form Name"}
            type="apiForms"
            subType="apiForm"
            dispatcher={dispatcher}
            setLoad={(value) => setLoading(value)}
            setdata={(val:any) => setFormNameSelected(val)}
            reload={false}
          />
        )}
    </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode,
    organizationData: state.organization.organizationData,
    authToken: state.user.authToken,
    constants:state.constants.data,
    userPreference: state.userPreference.data,
    organizationResources:state.organizationResources.data
  };
};
export default connect(mapStateToProps)(DistributionModel);
