import React, { FunctionComponent, useEffect, useRef, useState, } from "react";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
// import styles from "../../BulkEditModal/BulkEditModal.module.css";
import styles from "./UserMobileUpdateOTPModal.module.css";
// import "../../../Screens/AuditLogs/AuditLog.css";
import Loading from "../../../Components/Loading/Loading";
import Backdrop from "../../../Components/Backdrop/Backdrop";
import { motion } from "framer-motion";
import { IoIosClose } from "react-icons/io";
import Label from "../../../Components/Label";
import TextInput from "../../../Components/TextInput/TextInput";
import { themeColors } from "../../../Components/theme";
import axios from "axios";
import { url } from "../../../Values/constants";
import axiosHandler from "../../../Services/axiosHandler";

type props = {
    close?: () => void;
    open?: boolean;
    theme?: any;
    user?: any;
    oldMobileNumber?: any;
    newMobileNumber?: any;
    onVerify?: any;
    oldMobileOtp?: any,
    newMobileOtp?: any,
    // setOldMobileOtp?: any,
    // setNewMobileOtp?: any
};

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 1,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
};

const UserMobileUpdateOTPModal: FunctionComponent<props> = ({
    close,
    open,
    theme,
    user,
    oldMobileNumber,
    newMobileNumber,
    onVerify,
    oldMobileOtp,
    newMobileOtp,
    // setOldMobileOtp,
    // setNewMobileOtp
}) => {
    // const oldMobileOtpRef: any = useRef();
    // const newMobileOtpRef: any = useRef();
    const dispatcher = useDispatch();

    const onResend = async (mobileNumber: any) => {
        try {
            const params = { contact_no: mobileNumber };
            const response = await axiosHandler.post('/auth/generateOtp', params);
            dispatcher(showSnackbarAction("OTP sent successfully", "success"));
        } catch (error) {
            dispatcher(showSnackbarAction("An error occured, please try again", "error"));
        }
    };

    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark };

    // const OtpInput = ({mobileNumber,setOtp}:any) => {
    //     return (
    //         <>
    //             <div className="d-flex align-items-center gap-4">
    //                 <p>Mobile:</p>
    //                 <p>+91-{mobileNumber}</p>
    //             </div>
    //             <div className="d-flex align-items-center gap-4">
    //                 <p>Enter Verification Code:</p>
    //                 <input type="number" onChange={(e)=>{
    //                     setOtp(e.target.value)
    //                 }} />
    //                 <button onClick={()=>{onResend(mobileNumber)}}>Resend Code</button>
    //             </div>
    //         </>
    //     )
    // }
    return (
        <>
            {/* {load === true && <Loading />} */}
            {open && <Backdrop>
                <motion.div
                    key="modal"
                    className="card px-4 py-2 shadow rounded"
                    style={{
                        position: "absolute", minWidth: "400px",
                        ...containerStyles
                    }}
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className={styles.firstDiv}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                                <h6 className="fw-bold"
                                >
                                    Change Mobile No.
                                </h6>
                                <div className={styles.cross} onClick={close}>
                                    <IoIosClose size={35} color={"#808080"} />
                                </div>
                            </div>
                            <p>Enter Verification Code sent on +91-{oldMobileNumber} & +91-{newMobileNumber} to change your mobile number.</p>
                        </div>
                    </div>
                    {/* <div className={styles.line}></div> */}

                    <div
                        className={styles.divide}
                    >
                        {/* <p style={{color:"#279ea0"}}><b>OTP has been sent to {organizationData.otp_verification_mail ? organizationData.otp_verification_mail : ""}</b></p> */}
                        {/* <h6>Enter OTP Code</h6> */}
                        <div>
                            {/* <OtpInput mobileNumber={oldMobileNumber} setOtp={setOldMobileOtp} />
                            <OtpInput mobileNumber={newMobileNumber} setOtp={setNewMobileOtp} /> */}
                            <>
                                <div className={styles.textContainer}>
                                    <p className={styles.flexGrow}>Old Mobile:</p>
                                    <p className={styles.flexGrow}>+91-{oldMobileNumber}</p>
                                    <span className={styles.flexGrow}></span>
                                </div>
                                <div className={styles.textContainer}>
                                    <p className={styles.flexGrow}>Enter Verification Code:</p>
                                    {/* <input className={styles.flexGrow} type="number" max={9999} onChange={(e) => {
                                        setOldMobileOtp(e.target.value)
                                    }} /> */}
                                      {/* <input type="text" pattern="\d*" maxLength={4} className={styles.flexGrow} onChange={(e) => {
                                        setOldMobileOtp(e.target.value)
                                    }}></input> */}
                                    <TextInput
                                        title={""}
                                        style={{ backgroundColor: "#fff" }}
                                        ref={oldMobileOtp}
                                        disabled={false}
                                        minValue={'0'}
                                        type="text"
                                        length={true}
                                        inputPattern={/^[0-9]{0,4}$/}
                                    />
                                    <div className={styles.flexGrow}>
                                        <button className="btn btn-link" onClick={() => { onResend(oldMobileNumber) }}>Resend Code</button>
                                    </div>
                                </div>
                            </>
                            <div style={{marginTop:"1rem"}}></div>
                            <>
                                <div className={styles.textContainer}>
                                    <p className={styles.flexGrow}>New Mobile:</p>
                                    <p className={styles.flexGrow}>+91-{newMobileNumber}</p>
                                    <span className={styles.flexGrow}></span>
                                </div>
                                <div className={styles.textContainer}>
                                    <p className={styles.flexGrow}>Enter Verification Code:</p>
                                    {/* <input className={styles.flexGrow} type="number" max={9999} onChange={(e) => {
                                        setNewMobileOtp(e.target.value)
                                    }} /> */}
                                    {/* <input type="text" pattern="\d*" maxLength={4} className={styles.flexGrow} onChange={(e) => {
                                        setNewMobileOtp(e.target.value)
                                    }}></input> */}
                                       <TextInput
                                        title={""}
                                        style={{ backgroundColor: "#fff" }}
                                        ref={newMobileOtp}
                                        disabled={false}
                                        minValue={'0'}
                                        type="text"
                                        length={true}
                                        inputPattern={/^[0-9]{0,4}$/}
                                    />
                                    <div className={styles.flexGrow}>
                                        <button className="btn btn-link" onClick={() => { onResend(newMobileNumber) }}>Resend Code</button>
                                    </div>
                                </div>
                            </>
                            {/* <>
                                <div className="d-flex align-items-center gap-4">
                                    <p>Mobile:</p>
                                    <p>+91-{newMobileNumber}</p>
                                </div>
                                <div className="d-flex align-items-center gap-4">
                                    <p>Enter Verification Code:</p>
                                    <input type="number" onChange={(e) => {
                                        setNewMobileOtp(e.target.value)
                                    }} />
                                    <button onClick={() => { onResend(newMobileNumber) }}>Resend Code</button>
                                </div>
                            </> */}
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <button onClick={() => { onVerify( oldMobileOtp.current.value.trim(), newMobileOtp.current.value.trim()) }} className={styles.confirmBtn}>Confirm
                                </button>
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </motion.div>
            </Backdrop>}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.theme.isLightMode,
    };
};

export default connect(mapStateToProps)(UserMobileUpdateOTPModal);