export const emailValidate = (email: string) => {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());
    if (valid) {
      return "";
    } else {
      return "Invalid Email";
    }
  }else{
    return "Invalid Email";
  }
};

export const passwordValidate = (password: string) => {
  if (password.length >= 8) {
    return "";
  } else {
    return "Minimum 8 char password required";
  }
};

export const phoneValidate = (phone: string) => {
  const re = /[0-9]{2}\d{8}/;
  const valid = re.test(phone);
  if (valid) {
    return "";
  } else {
    return "Invalid Phone No.";
  }
};

export const validateNumber = (request: {
  contact_no: string;
  country: string;
}) => {
  let contact = request.contact_no.replace(/ /g, "").replace(/-/g, "");
  if (request.country === "United Arab Emirates") {
    if (
      contact.startsWith("+") &&
      (contact.length === 13 || contact.length === 12)
    ) {
      return { country_code: "+971", contact_no: contact.slice(3) };
    } else if (
      contact.startsWith("971") &&
      (contact.length === 12 || contact.length === 11)
    ) {
      return { country_code: "+971", contact_no: contact.slice(2) };
    } else if (contact.length === 10 || contact.length === 9) {
      return { country_code: "+971", contact_no: contact };
    } else {
      return { error: "Invalid Contact" };
    }
  } else {
    if (contact.startsWith("+") && contact.length === 13) {
      return { country_code: "+91", contact_no: contact.slice(3) };
    } else if (contact.startsWith("91") && contact.length === 12) {
      return { country_code: "+91", contact_no: contact.slice(2) };
    } else if (contact.length === 10) {
      return { country_code: "+91", contact_no: contact };
    } else {
      alert("Invalid Contact No.!!")
      return { error: "Invalid Contact" };
    }
  }
};

export const gstValidate = (gstin: any) => {
  const re = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const valid = re.test(gstin);
  if (valid) {
    return "";
  } else {
    return "Invalid GST Number";
  }
};

export const aphabetValidator = (alpha: string) => {
  // const re = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/;

  const alphaRegex = /^[A-Za-z]+$/;
  const alphaNumericRegex = /^(?=.*[A-Za-z])[A-Za-z0-9]+$/;

  if (alphaRegex.test(alpha) || alphaNumericRegex.test(alpha)) {
    return "";
  } else {
    return "Invalid Name";
  }
};

export const contactValidator = (phone: string) => {
  const re = /^[0-9]{10,11}$/; // Regex for 10 to 11 digits only
  const valid = re.test(phone);
  if (valid) {
    return "";
  } else {
    return "Invalid Phone No.";
  }
};


export const checkPhoneNumberlength = (phone: string) => {
  const re = /^\d{10}$/;
  const valid = re.test(phone);
  if (valid) {
    return "";
  } else {
    return "Invalid Phone No.";
  }
};

export const alphabetValidatorProjectName = (alpha:string) => {
  // Trim the input to remove leading and trailing spaces
  let data = alpha.trim();

  // Regex to allow only alphabets with spaces in between but not at the start or end
  const alphaWithSpacesRegex = /^[A-Za-z]+([ ][A-Za-z]+)*$/;

  // Regex to allow alphabets, numbers, and spaces in between but not at the start or end
  const alphaNumericWithSpacesRegex = /^(?=.*[A-Za-z])[A-Za-z0-9]+( [A-Za-z0-9 ]+)*$/;

  // Check if the input matches either of the regex patterns
  if (alphaWithSpacesRegex.test(data) || alphaNumericWithSpacesRegex.test(data)) {
    return "";
  } else {
    return "Invalid Name";
  }
};

export const empIdValidator = (empId:any) => {
  if (empId) {
    const empIdRegex = /^[A-Za-z]+[-_]\d+$/;
  const valid = empIdRegex.test(empId);
  if (valid) {
    return "";
  } else {
    return "Invalid Format Ex- EMP-";
  }
}else{
    return ""
  }
};

export const emailValidator = (email: string) => {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());
    if (valid) {
      return "";
    } else {
      return "Invalid Email";
    }
  } else {
    return "";
  }
}
export const userContactValidator = (phone: string) => {

  if (!/^\d{10,10}$/.test(phone)) {
    return "Invalid Phone No.";
  }

  // Check if the mobile number starts with a 0
  if (phone.startsWith("0")) {
    return "Invalid Phone No.";
  }

  // Check if the mobile number starts with 6, 7, 8, or 9
  if (!/^[6789]/.test(phone)) {
    return "Invalid Phone No.";
  }

  return "";
};
