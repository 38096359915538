import Firebase from "firebase/app";
import { firestore, functions } from "../Firebase";
import { showSnackbarAction, setFilterObject, setCallRecordingStatus } from "../Redux/actions";
import { country_code } from "../Values/constants";
import { url } from '../Values/constants';
import axios from "axios";
import { convertToFilterString } from "../Values/utils";
import {emailValidate, emailValidator} from "../Values/validatorsnew"
import axiosHandler from "./axiosHandler";

let organizationId = "";

// export const fetchOrganizations = (
//   setData: (data: any[]) => void
// ) => {
//   const subscriber = firestore
//     .collection("organizations")
//     .onSnapshot((organizations) => {
//       const data: any[] = [];
//       if (organizations) {
//         organizations.forEach((organization) => {
//           data.push({
//             ...organization.data(),
//             organization_id: organization.id,
//           });
//         });
//         setData(data);
//       } else {
//         setData([]);
//       }
//     });
//   return subscriber;
// };

// export const createOrganization = (
//   organization_name: string,
//   mobile_number: number,
//   email_id: string,
//   address: string,
//   country: string,
//   state: string,
//   city: string,
//   pincode: number,
//   admin_first_name: string,
//   admin_last_name: string,
//   admin_contact_number: number,
//   admin_email_id: string,
//   no_of_employees: number,
//   business_domain:string,
//   history: any,
//   dispatcher: any,
//   window: any,
//   setLoad: (data: boolean) => void,
//   cost_per_license: number,
//   grace_period: number,
//   valid_from: number,
//   valid_till: any,
//   gst_number: any,
//   shift_active_status:any,
//   organization_active_status:any,
// ) => {
//   var currentTime = new Date();
//   let Valid_From = new Date(valid_from)
//   Valid_From.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
//   let vf =Firebase.firestore.Timestamp.fromDate(Valid_From)
//   let Valid_Till = new Date(valid_till)
//   Valid_Till.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
//   let vt =Firebase.firestore.Timestamp.fromDate(Valid_Till)
//   let password = "";
//   if (admin_first_name.length < 4) {
//     password =
//       admin_first_name.toUpperCase() +
//       admin_last_name
//         .slice(0, 4 - admin_first_name.length)
//         .toUpperCase() +
//       "@" +
//       admin_contact_number.toString().slice(0, 4);
//   } else {
//     password =
//       admin_first_name.slice(0, 4).toUpperCase() +
//       "@" +
//       admin_contact_number.toString().slice(0, 4);
//   }
//   const org_id = firestore
//     .collection("organizations")
//     .doc();
//   functions
//     .httpsCallable("createOrganization")(
//       JSON.stringify({
//         email: admin_email_id.toLowerCase(),
//         password: password,
//         displayName:
//           admin_first_name + " " + admin_last_name,
//         organization_id: org_id.id,
//       })
//     )
//     .then((response) => {
//       org_id
//         .set(
//           {
//             organization_name: organization_name,
//             mobile_number: mobile_number,
//             email_id: email_id.toLowerCase(),
//             address: address,
//             country: country,
//             state: state,
//             city: city,
//             created_at: Firebase.firestore.Timestamp.now(),
//             pincode: pincode,
//             admin_first_name: admin_first_name,
//             admin_last_name: admin_last_name,
//             admin_contact_number: admin_contact_number,
//             admin_email_id: admin_email_id.toLowerCase(),
//             no_of_employees: no_of_employees,
//             business_domain:business_domain,
//             teams: [],
//             designations: [],
//             status: "ACTIVE",
//             auth_id: response.data,
//             cost_per_license: Number(cost_per_license),
//             grace_period: Number(grace_period),
//             valid_from: vf,
//             valid_till: vt,
//             GSTIN: gst_number,
//             current_active_status:shift_active_status,
//             organization_active_status:organization_active_status
//           },
//           { merge: true }
//         )
//         .then(() => {
//           firestore
//             .collection("users")
//             .doc(response.data)
//             .set({
//               user_first_name: admin_first_name,
//               user_last_name: admin_last_name,
//               user_email: admin_email_id.toLowerCase(),
//               team: "",
//               reporting_to: "",
//               contact_no: admin_contact_number,
//               designation: "Organization Admin",
//               status: "ACTIVE",
//               organization_id: org_id.id,
//               created_by: "Super Admin",
//               created_at:
//                 Firebase.firestore.Timestamp.now(),
//               profile: "Admin",
//               uid: response.data,
//               user_image: "",
//               device_id: "",
//               country: country,
//               state: state,
//             });
//           firestore
//             .collection("organizationResources")
//             .doc(org_id.id)
//             .set({
//               organization_id: org_id.id,
//               permission :{
//                 "Sales" :[
//                   "Budget",
//                   "Contact No.",
//                   "Created At",
//                   "Customer Name",
//                   "Email",
//                   "Source",
//                   "Assign Time",
//                   "Owner",
//                   "Follow Up Date Time",
//                   "Follow Up Type",
//                   "Property Type",
//                   "Property Stage",
//                   "Project",
//                   "Location"
//                 ],
//                 "Team Lead" :[
//                   "Budget",
//                   "Contact No.",
//                   "Created At",
//                   "Created By",
//                   "Customer Name",
//                   "Email",
//                   "Source",
//                   "Assign Time",
//                   "Location",
//                   "Project",
//                   "Property Stage",
//                   "Property Type",
//                   "Follow Up Date Time",
//                   "Owner"
//                 ]
//               },
//               projects:[]
//             });
//           dispatcher(
//             showSnackbarAction(
//               "Organization Created!!",
//               "success"
//             )
//           );
//           setLoad(false);
//           history.replace("/");
//         })
//         .catch((error) => {
//           console.log("Firstore organization error", error);
//           setLoad(false);
//         });
//     })
//     .catch((err) => {
//       console.log("role error", err);

//       dispatcher(
//         showSnackbarAction(
//           "Organization Already exists!!",
//           "error"
//         )
//       );
//       setLoad(false);
//     });
// };

// export const editOrganization = (
//   organization_id: string,
//   organization_name: string,
//   mobile_number: number,
//   email_id: string,
//   address: string,
//   country: string,
//   state: string,
//   city: string,
//   pincode: number,
//   admin_first_name: string,
//   admin_last_name: string,
//   admin_contact_number: number,
//   admin_email_id: string,
//   contact_person_email: string,
//   contact_person_no: number,
//   no_of_employees: number,
//   auth_id: string,
//   // created_at:any,
//   business_domain:string,
//   history: any,
//   dispatcher: any,
//   setLoad: (data: boolean) => void,
//   cost_per_license: number,
//   grace_period: number,
//   valid_from: any,
//   valid_till: any,
//   gst_number: any,
//   shift_active_status:any,
//   organization_active_status:any,
// ) => {
//   var currentTime = new Date();
//   let ValidFrom = new Date(valid_from)
//   ValidFrom.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
//     let vf =Firebase.firestore.Timestamp.fromDate(ValidFrom)
//   let ValidTill = new Date(valid_till)
//   ValidTill.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
//   let vt =Firebase.firestore.Timestamp.fromDate(ValidTill)
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         organization_name: organization_name,
//         mobile_number: mobile_number,
//         email_id: email_id.toLowerCase(),
//         address: address,
//         country: country,
//         state: state,
//         city: city,
//         // created_at: Created,
//         pincode: pincode,
//         admin_first_name: admin_first_name,
//         admin_last_name: admin_last_name,
//         admin_contact_number: admin_contact_number,
//         admin_email_id: admin_email_id.toLowerCase(),
//         contact_person_email: contact_person_email.toLowerCase(),
//         contact_person_no: contact_person_no,
//         no_of_employees: no_of_employees,
//         business_domain:business_domain,
//         teams: [],
//         designations: [],
//         status: "ACTIVE",
//         auth_id: auth_id,
//         cost_per_license: Number(cost_per_license),
//         grace_period: Number(grace_period),
//         valid_from: vf,
//         valid_till: vt,
//         GSTIN: gst_number,
//         current_active_status:shift_active_status,
//         organization_active_status:organization_active_status
//       },
//       { merge: true }
//     )
//     .then(() => {
//       dispatcher(
//         showSnackbarAction(
//           "Organization Details Updated!!",
//           "success"
//         )
//       );
//       setLoad(false);
//       history.replace("/");
//     })
//     .catch((error) => {
//       console.log("Edit Org Error:", error);
//       dispatcher(
//         showSnackbarAction("Error!! Try Again!!", "error")
//       );
//       setLoad(false);
//     });
// };

export const fetchOrganizationsData = (
  email: string,
  setTeams: (data: any[]) => void,
  setDesignations: (data: any[]) => void,
  setId: (data: string) => void
) => {
  const subscriber = firestore
    .collection("organizations")
    .where("admin_email_id", "==", email)
    .onSnapshot((organization) => {
      if (organization) {
        const org = organization.docs[0];
        organizationId = org.id;
        setId(organizationId);

        setTeams(organization.docs[0].data().teams);
        setDesignations(
          organization.docs[0].data().designations
        );
      } else {
        console.log("org not found");
      }
    });
  return subscriber;
};  ///////used in dashbord //////

export const updatePrimaryLeader = (
  organization_id: string,
  primary_lead_manager_email: string
) => {
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        primary_lead_manager: primary_lead_manager_email,
      },
      { merge: true }
    )
    .then(() => {
      console.log("Primary lead manager updated");
    })
    .catch((e) => {
      console.log("Error:", e);
    });
};

// export const updateTeams = (
//   teamName: string,
//   setLoad: (value: boolean) => void,
//   organization_id: string,
//   setShowTeamModal: (value: boolean) => void,
//   dispatcher: any
// ) => {
//   setLoad(true);
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         teams:
//           Firebase.firestore.FieldValue.arrayUnion(
//             teamName
//           ),
//       },
//       { merge: true }
//     )
//     .then(() => {
//       dispatcher(
//         showSnackbarAction("Team Added", "success")
//       );
//       setLoad(false);
//       setShowTeamModal(false);
//     })
//     .catch((e) => {
//       console.log("Error", e);
//     });
// };

export const updateTeams = async (
  data: string,
  setLoad: (value: boolean) => void,
  organization_id: string,
  setShowTeamModal: (value: boolean) => void,
  dispatcher: any
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
        data
};
    // const response = await fetch(url + '/organizations/updateOrg', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     organization_id:organization_id,
    //     data
    //   })
    // });

    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    // const responseData = await response.json();

    if (response) {
      dispatcher(showSnackbarAction("Team Added", "success"));
      setLoad(false);
      setShowTeamModal(false);
    } else {
      console.log("Error:", response);
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const updateDesignations = (
  designation: string,
  setLoad: (value: boolean) => void,
  organization_id: string,
  setShowDesigModal: (value: boolean) => void,
  dispatcher: any
) => {
  setLoad(true);
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        designations:
          Firebase.firestore.FieldValue.arrayUnion(
            designation
          ),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("Designation Added", "success")
      );
      setLoad(false);
      setShowDesigModal(false);
    })
    .catch((e) => {
      console.log("Error", e);
    });
}; //// using updateTeams ///////

// export const updateOrganizationStatus = (
//   organization_id: string,
//   status: string,
//   setLoad: (data: boolean) => void
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         status: status,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       console.log("Status updated");
//       setLoad(false);
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//     });
// };

export const updateOrganizationStatus = async (
  organization_id: string,
  status: string,
  setLoad: (data: boolean) => void
) => {
  setLoad(true);

  try {
    // const response = await fetch(url + '/organizations/updateOrg', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     organization_id:organization_id,
    //     status: status,
    //   })
    // });

    // const responseData = await response.json();
    const bodydata = {
      organization_id:organization_id,
      status: status,
};

    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      console.log("Status updated");
      setLoad(false);
    } else {
      console.log("Error:", response);
      setLoad(false);
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
  }
};

export const updateAPIStatus = (
  id: string,
  status: string,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection("apiTokens")
    .doc(id)
    .set(
      {
        status: status,
      },
      { merge: true }
    )
    .then(() => {
      console.log("Status updated");
      setLoad(false);
    })
    .catch((e) => {
      console.log("Error:", e);
      setLoad(false);
    });
};

export const fetchUsers = (
  setData: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("users")
    .onSnapshot((users) => {
      const data: any[] = [];

      if (users) {
        users.forEach((users) => {
          data.push(users.data());
        });

        setData(data);
      } else {
        setData([]);
      }
    });
  return subscriber;
};

// create user and send them generated password to login
export const createUser = async (
  user_email: string,
  user_first_name: string,
  user_last_name: string,
  team: any,
  reporting_to: any,
  contact_no: string,
  designation: any,
  branch: any,
  status: string,
  profile: string,
  dispatcher: any,
  history: any,
  id: any,
  created_by: string,
  country: string,
  state: string,
  multiple: boolean,
  setLoad?: (data: boolean) => void,
  reportingToProfile?: any,
  groupHeadName?: any,
  employee_id?: any,
  authToken?: any,
) => {
  let check = false;
  if (reportingToProfile) {
    check = true;
    let reportingProfile = "";
    reportingToProfile.forEach((item: any) => {
      if (item.value === reporting_to) {
        reportingProfile = item.label;
      }
    });

    if (profile === "CEO") {
      if (
        reportingProfile === "Sales" ||
        reportingProfile === "Team Lead"
      ) {
        dispatcher(
          showSnackbarAction(
            "CEO Cannot Report To Lower Profiles!!",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else if (profile === "Lead Manager") {
      if (
        reportingProfile === "Sales" ||
        reportingProfile === "Team Lead" ||
        reportingProfile === "CEO"
      ) {
        dispatcher(
          showSnackbarAction(
            "Lead Manager Cannot Report To Lower Profiles!!",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else if (profile === "Team Lead") {
      if (reportingProfile === "Sales") {
        dispatcher(
          showSnackbarAction(
            "Team Lead Cannot Report To Lower Profiles",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else {
      check = false;
    }
  }
  if (check === false) {
    let result: { data: any } = { data: true };
    let result2: { data: any } = { data: true };
    if (multiple === false) {
      const funcData = JSON.stringify({
        contact_no: contact_no,
        organization_id: id
      });
      result = await functions.httpsCallable("checkUser")(
        funcData
      );

      if(employee_id !== ""){
        const funcData2 = JSON.stringify({
          employee_id: employee_id,
          organization_id: id
        });
        result2 = await functions.httpsCallable("checkUser2")(
          funcData2
        );
      }
    }

    if (result.data === false) {
      dispatcher(
        showSnackbarAction(
          "User Contact Number Already Exists!!",
          "warning"
        )
      );
      setLoad && setLoad(false);
    } 
    else if (result2.data === false) {
      dispatcher(
        showSnackbarAction(
          "Employee Id Already Exists!!",
          "warning"
        )
      );
      setLoad && setLoad(false);
    }
    else {
      try {
        let password = "";
        if (user_first_name.length < 4) {
         if(user_last_name.length <4){
          password=user_first_name.toUpperCase()+user_last_name.toUpperCase()+"@"+ contact_no.toString().slice(0, 4);
           if(password.length<8){
            password=user_email.slice(0,8-password.length).toUpperCase()+password;
           }
         }else{
          password =
            user_first_name.toUpperCase() +
            user_last_name
              .slice(0, 4 - user_first_name.length)
              .toUpperCase() +
            "@" +
            contact_no.toString().slice(0, 4);
         }

          
        } else {
          password =
            user_first_name.slice(0, 4).toUpperCase() +
            "@" +
            contact_no.toString().slice(0, 4);
        }

        console.log("passwordRishabh",password)
        const response = await functions.httpsCallable(
          "createUser"
        )(
          JSON.stringify({
            role: profile,
            organization_id: id,
            userData: {
              email: user_email,

              password: password,
              displayName:
                user_first_name + " " + user_last_name,
            },
          })
        );
        try {
          await firestore
            .collection("users")
            .doc(response.data)
            .set(
              {
                user_first_name,
                user_last_name: user_last_name
                  ? user_last_name
                  : "",
                user_email: user_email.toLowerCase(),
                team,
                reporting_to,
                contact_no,
                designation,
                status,
                organization_id: id,
                created_by: created_by,
                created_at:
                  Firebase.firestore.Timestamp.now(),
                profile,
                uid: response.data,
                user_image: "",
                device_id: "",
                country: country,
                state: state,
                branch: branch,
                group_head_name:(groupHeadName === "Select" || groupHeadName === "" || !groupHeadName) ? "" : groupHeadName,
                employee_id:employee_id?employee_id:""
              },
              { merge: true }
            );
            if(response.data){
              try {
                // axios.defaults.headers.common["x-access-token"] = authToken;
                const res: any = await axiosHandler.post(url + "/userAuthorization/update", {
                  userUid: response.data , UserProfile:profile,userCreation:true,organization_id:id
                });
              } catch (error) {
                console.log('error',error);
              }
            }
          dispatcher(
            showSnackbarAction("User Created!!", "success")
          );
          setLoad && setLoad(false);
          history.replace("/userManagement");
        } catch (error) {
          console.log("Firstore organization error", error);
          setLoad && setLoad(false);
        }
      } catch (error) {
        dispatcher(
          showSnackbarAction(
            "User Email ID Already exists!!",
            "error"
          )
        );
        setLoad && setLoad(false);
      }
    }
  }
};

export const setLeadPermission = async (
  organization_id: string,
  profile: string,
  options: any[],
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  organizationUsers: any[],
  profileData?:any,
  profileNotSelected?:any,
) => {
  // firestore
  //   .collection("organizationResources")
  //   .doc(organization_id)
  //   .set(
  //     {
  //       permission: {
  //         [profile]: options,
  //       },
  //     },
  //     { merge: true }
  //   )
  //   .then(() => {
  //     organizationUsers.forEach((user) => {
  //       if (user.profile === profile) {
  //         if (user.leadView) {
  //           Object.keys(user.leadView).forEach((key) => {
  //             let data: string[] = [];
  //             user.leadView[key].forEach((item: string) => {
  //               if (options.includes(item)) {
  //                 data.push(item);
  //               }
  //             });
  //             user.leadView[key] = data;
  //           });
  //           firestore.collection("users").doc(user.uid).set(
  //             {
  //               leadView: user.leadView,
  //             },
  //             { merge: true }
  //           );
  //         }
  //       }
  //     });

  //     dispatcher(
  //       showSnackbarAction(
  //         "Lead View Permission Updated",
  //         "success"
  //       )
  //     );
  //     setLoad(false);
  //     close();
  //   })
  //   .catch((error) => {
  //     console.log("Lead View Error", error);
  //     setLoad(false);
  //     close();
  //   });

  try {
    const apiData:any = {
      organization_id:organization_id,
      resource_type:'permission',
      permission: {
        [profile]:options,
        [profileNotSelected]:profileData===undefined?[]:profileData
      },
    }

    const res: any = await axiosHandler.put(url + "/organizationResources/updateOrg",apiData);
    dispatcher(
            showSnackbarAction(
              "Lead View Permission Updated",
              "success"
            )
          );
    setLoad(false);
    close();
    // console.log("YTGRFED", res.data)
  } catch (error:any) {
    console.log("Error",error)
    setLoad(false);
    close()
  }
};

export const fetchOrganizationLeadPermission = async (
  organization_id: string,
  setPermissions: (data: any) => void
) => {
  // const subscriber = firestore
  //   .collection("organizationResources")
  //   .doc(organization_id)
  //   .onSnapshot((resources) => {
  //     if (resources) {
  //       const resourceData = resources.data();
  //       if (resourceData?.permission) {
  //         setPermissions(resourceData?.permission);
  //       } else {
  //         setPermissions([]);
  //       }
  //     } else {
  //       console.log("Org not found");
  //     }
  //   });

    const res: any = await axiosHandler.get(
      url + "/organizationResources/fetchAll",
      {
        params: {
          organization_id:organization_id
        },
      }
    );

    if(res.data.data){
      res.data.data.forEach((val: any) => {
        if (val.resource_type === 'permission'){
          setPermissions(val['permission'])
          return;
        }
      })
    }

  return;
};

export const createApi = (
  organization_id: string,
  source: string,
  countryCode: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("apiTokens")
    .doc()
    .set({
      created_at: Firebase.firestore.Timestamp.now(),
      organization_id: organization_id,
      source: source,
      country_code: countryCode,
      status: "ACTIVE",
    })
    .then(() => {
      dispatcher(
        showSnackbarAction("API Created !!", "success")
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("API Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const editApi = (
  source: string,
  countryCode: string,
  token: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  editClose: () => void
) => {

  firestore
    .collection("apiTokens")
    .doc(token)
    .set(
      {
        source: source,
        country_code: countryCode,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("API Edited!!", "success")
      );
      setLoad(false);
      close();
      editClose();
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      console.log("Api edit error:", error);
      setLoad(false);
    });
};

export const createNews = (
  organization_id: string,
  areaName: string,
  link: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("news")
    .doc(organization_id)
    .set(
      {
        news: Firebase.firestore.FieldValue.arrayUnion({
          created_at: Firebase.firestore.Timestamp.now(),
          name: areaName,
          link: link,
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("News Link Added !!", "success")
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("News Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};
export const deleteNews = (
  organization_id: string,
  filterDataArr: any,
  dispatcher: any,
) => {
  firestore
    .collection('news')
    .doc(organization_id).set(
      { news: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('News Deleted', 'success'));
      window.location.reload()
    })
    .catch((error) => {
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
    });
};
export const fetchApi = (
  organization_id: string,
  setAPI: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .onSnapshot((apis) => {
      const data: any[] = [];

      if (apis) {
        apis.forEach((api) => {
          data.push({ ...api.data(), api_key: api.id });
        });
        setAPI(data);
      } else {
        console.log("Api error");
      }
    });
  return subscriber;
};

export const fetchNews = (
  organization_id: string,
  setNews: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("news")
    .doc(organization_id)
    .onSnapshot((news) => {
      if (news) {
        const newsData = news.data();
        if (newsData?.news) {
          setNews(newsData.news);
        } else {
          setNews([]);
        }
      } else {
        console.log("organization not found");
      }
    });
  return subscriber;
};

export const fetchApiData = (
  organization_id: string,
  setAPIData: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .onSnapshot((apis) => {
      const data: any[] = [];
      if (apis) {
        apis?.docs?.forEach((api) => {
          if (typeof (api?.data().associate_contact) === 'string') {
            let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
            data.push({ ...api.data(), "associate_contact": associateContact });
          }
        });
        setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  return subscriber;
};

export const fetchApiFilterData = (
  organization_id: string,
  setAPIData: (data: any[]) => void,
  prevDate: any,
  currentDate: any
) => {
  const subscriber = firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .where("created_at", ">=", prevDate)
    .where("created_at", "<=", currentDate)
    .onSnapshot((apis) => {
      const data: any[] = [];
      if (apis) {
        apis.docs.forEach((api) => {
          if (typeof (api?.data().associate_contact) === 'string') {
            let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
            data.push({ ...api.data(), "associate_contact": associateContact });
          }
        });
        setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  return subscriber;
};

export const correctLeadCount = (
  organization_id: string,
  setLoad: (data: boolean) => void,
  dispatcher: any
) => {
  firestore
    .collection("contacts")
    .where("organization_id", "==", organization_id)
    .get()
    .then((leads) => {
      const finalData: {
        [key: string]: {
          [key: string]: { [key: string]: number };
        };
      } = {};

      leads.forEach((lead) => {
        const leadData = lead.data();
        const uid =
          leadData.uid === ""
            ? "NOTASSIGNED"
            : leadData.uid;
        if (
          leadData.organization_id === "" ||
          leadData.stage === ""
        ) {
          return;
        }
        if (finalData[leadData.organization_id]) {
          if (finalData[leadData.organization_id][uid]) {
            if (
              finalData[leadData.organization_id][uid][
              leadData.stage
              ]
            ) {
              finalData[leadData.organization_id][uid][
                leadData.stage
              ] += 1;
            } else {
              finalData[leadData.organization_id][uid][
                leadData.stage
              ] = 1;
            }
          } else {
            finalData[leadData.organization_id][uid] = {
              [leadData.stage]: 1,
            };
          }
        } else {
          finalData[leadData.organization_id] = {
            [uid]: { [leadData.stage]: 1 },
          };
        }
      });

      Object.keys(finalData).forEach((orgId) => {
        Object.keys(finalData[orgId]).forEach((user) => {
          if (finalData[orgId][user]["INTERESTED"]) {
            finalData[orgId][user]["FOLLOWUP"] =
              finalData[orgId][user]["INTERESTED"];
          } else {
            finalData[orgId][user]["FOLLOWUP"] = 0;
          }
          if (finalData[orgId][user]["CALLBACK"]) {
            finalData[orgId][user]["FOLLOWUP"] +=
              finalData[orgId][user]["CALLBACK"];
          }
        });
      });

      firestore
        .collection("counts")
        .doc(organization_id)
        .set(finalData[organization_id])
        .then(() => {
          setLoad(false);

          dispatcher(
            showSnackbarAction(
              "Counts Corrected!!",
              "success"
            )
          );
        });
    });
};

export const updateApiPrimaryLead = (
  organization_id: string,
  uid: string,
  primary_lead_manager_email: string
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((docs) => {
      const batch = firestore.batch();
      docs.forEach((doc) => {
        batch.set(
          doc.ref,
          {
            primary_lead_manager_email:
              primary_lead_manager_email,
            primary_lead_manager_uid: uid,
          },
          { merge: true }
        );
      });

      batch
        .commit()
        .then(() => {
          console.log("updateApiPrimaryLead", "success");
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    });
};

export const changeLeadsStage = (
  leadsList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  leadsList.forEach((item) => {
    firestore
      .collection("contacts")
      .doc(item)
      .set(
        {
          stage: "FRESH",
          next_follow_up_type: "",
          next_follow_up_date_time: "",
          not_int_reason: "",
          lost_reason: "",
          other_not_int_reason: "",
          other_lost_reason: "",
        },
        { merge: true }
      )
      .then(() => {
        dispatcher(
          showSnackbarAction("Lead Stage Update", "success")
        );
        setLoad(false);
      })
      .catch((err) => {
        console.log("Lead Stage Error:", err);
        dispatcher(
          showSnackbarAction("Please Try Again!!", "error")
        );
        setLoad(false);
      });
  });
};

export const createCustomButton = (
  organization_id: string,
  name: string,
  url: string,
  parameters: object | undefined,
  headers: object | undefined,
  type: string,
  setLoad: (data: boolean) => void,
  dispatcher: any,
  close: () => void
) => {
  firestore
    .collection("customButtons")
    .doc()
    .set({
      created_at: Firebase.firestore.Timestamp.now(),
      organization_id: organization_id,
      name: name,
      url: url,
      parameters: parameters ? parameters : {},
      headers: headers ? headers : {},
      type: type,
    })
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Custom Button Created !!",
          "success"
        )
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("Custom Button Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};


export const fetchCustomButtons = (
  organization_id: string,
  setCustomButton: (data: any[]) => void
) => {
  sessionStorage.getItem('org')
  type props = {
    parameters: any;
    type: string;
    headers: any;
    created_at: any;
    name: string;
    url: string;
    organization_id: string;
  };


  const subscriber = firestore
    .collection("customButtons")
    .where("organization_id", "==", sessionStorage.getItem('org'))
    .onSnapshot((customButton) => {
      if (customButton) {
        const data: any[] = [];
        customButton?.forEach((api) => {
          data?.push(api?.data());
        });
        setCustomButton(data);
        console.log('data', data)
      } else {
        console.log("custom button error");
      }
    });
  return subscriber;
};

// export const updateSettings = (
//   organization_id: string,
//   shift_start_time:any,
//   shift_end_time:any,
//   shift_reminder:any,
//   shift_reminder_after:any,
//   days_before_notifying:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         shift_start_time,
//         shift_end_time,
//         shift_reminder,
//         shift_reminder_after,
//         days_before_notifying
//       },
//       { merge: true }
//     )
//     .then(() => {
//       console.log("Shift Timings Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Updated", "success")
//       );
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updateSettings = async (
  organization_id: string,
  shift_start_time: any,
  shift_end_time: any,
  shift_reminder: any,
  shift_reminder_after: any,
  days_before_notifying: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    // const response = await fetch(url + '/organizations/updateOrg', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     organization_id:organization_id,
    //     shift_start_time:shift_start_time,
    //     shift_end_time:shift_end_time,
    //     shift_reminder:shift_reminder,
    //     shift_reminder_after:shift_reminder_after,
    //     days_before_notifying:days_before_notifying
    //   })
    // });
    const bodydata = {
      organization_id:organization_id,
      shift_start_time:shift_start_time,
      shift_end_time:shift_end_time,
      shift_reminder:shift_reminder,
      shift_reminder_after:shift_reminder_after,
      days_before_notifying:days_before_notifying
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);
    // const responseData = await response.json();

    if (response) {
      console.log("Shift Timings Updated");
      setLoad(false);
      dispatcher(showSnackbarAction("Settings Updated", "success"));
    } else {
      console.log("Error:", response);
      setLoad(false);
      dispatcher(showSnackbarAction("An error occured, please try again", "error"));
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
    dispatcher(showSnackbarAction("An error occured, please try again", "error"));
  }
};


// export const updateOrgActivation = (
//   organization_id: string,
//   current_active_status:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         current_active_status,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       console.log("Current Active Status Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updateOrgActivation = async (
  organization_id: string,
  current_active_status: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    // const response = await fetch(url + '/organizations/updateOrg', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     organization_id:organization_id,
    //     current_active_status:current_active_status,
    //   })
    // });

    // const responseData = await response.json();
    const bodydata = {
      organization_id:organization_id,
      current_active_status:current_active_status,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      // console.log("Current Active Status Updated");
      setLoad(false);
      dispatcher(showSnackbarAction("Settings Update", "success"));
    } else {
      // console.log("Error:", response);
      setLoad(false);
      dispatcher(showSnackbarAction("An error occured, please try again", "error"));
    }
  } catch (error) {
    // console.log("Error:", error);
    setLoad(false);
    dispatcher(showSnackbarAction("An error occured, please try again", "error"));
  }
};

export const checkApiSyncStatus = (
  organization_id: string,
  TOKEN:string,
  setLeadsNotSynced:any,
  leadsNotSynced:any,
  // setAPIData: (data: any[]) => void,
  // prevDate: any,
  // currentDate: any
) => {
  const endDate = new Date(); // Today's date
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 3);

  // const subscriber = 
  firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .where("status", "==","SUCCESS")
    .where("created_at", ">=", startDate)
    .where("created_at", "<=", endDate)
    .onSnapshot((apis) => {
      // const data: any[] = [];
      if (apis) {
        apis.docs.forEach(async (api) => {
          firestore.collection("contacts").doc(api?.data()?.lead_id).get()
            .then((doc)=>{
              if(doc.data()?.transfer_status === false){
                const apiData = {
                  lead_id:api?.data()?.lead_id,
                }
                axios
                  .post(`${url}/leads/checkIfLeadExists`, apiData, {headers: {'x-access-token': TOKEN}})
                  .then(async (res:any) => {
                    if(res.data.data.lead_exists == false) {
                      setLeadsNotSynced([...leadsNotSynced,api?.data()?.lead_id]);
                    }
                  })
                  .catch(err=>{
                    console.log("error",err);
                  })
              }
            })
            .catch(err=>{
              console.log("error",err)
            })

          // console.log("amamsjskxxll",api?.data());
          // const leadRef = firestore.collection('contacts').doc(api?.data()?.lead_id);
          // const leadDoc = await leadRef.get();   
          //   if (!leadDoc.exists) {
          //       console.log('No such document!');
          //   } else {
          //     axios
          //     .post(`${url}/leads/checkIfLeadExists`, apiData, {headers: {'x-access-token': TOKEN}})
          //     .then(async (res:any) => {
          //       if(res.data.data.lead_exists == false) {
          //         leadsNotSynced.push(api?.data()?.lead_id);
          //       }
          //     })
          //     .catch(err=>{
          //       console.log("error",err);
          //     })
          //   }
          // if (typeof (api?.data().associate_contact) === 'string') {
          //   let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
          //   data.push({ ...api.data(), "associate_contact": associateContact });
          // }
        });
        // setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  // return subscriber;
};

export const syncApiLeads = (
  TOKEN:string,
  leadsNotSynced:any,
  setLoad:any,
  dispatcher:any,
) => {
  setLoad(true);
  leadsNotSynced.map(async (item:any) => {
    let leadData = await firestore.collection("contacts").doc(item).get();
    var afterData = JSON.parse(JSON.stringify(leadData.data()));
    afterData["id"] = item;
    afterData["created_at"] = {
      _seconds:leadData.data()?.created_at?.seconds,
      _nanoseconds:leadData.data()?.created_at?.nanoseconds,
    }
    afterData["lead_assign_time"] = {
      _seconds:leadData.data()?.lead_assign_time?.seconds,
      _nanoseconds:leadData.data()?.lead_assign_time?.nanoseconds,
    }
    // const headers = {
    //   "x-access-token": TOKEN,
    // };
    try {
      const res = await axiosHandler.post(`${url}/leads/insert`, afterData);
      // console.log("leads insert request sent from axios");
      console.log("test aman",res.data);
    } catch (err) {
      console.log("error insert contact", afterData["id"]);
    }
    setTimeout(() => {
      setLoad(false);
      dispatcher(showSnackbarAction(`${leadsNotSynced.length} Leads Synced`, "success"));
      window.location.reload();
    }, 2500);
  })
};

// export const updateOrgApproval = (
//   organization_id: string,
//   is_approval_enabled:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         is_approval_enabled,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       console.log("Current Active Status Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updateOrgApproval = async (
  organization_id: string,
  is_approval_enabled: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
      is_approval_enabled:is_approval_enabled,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      setLoad(false);
      dispatcher(showSnackbarAction("Settings Update", "success"));
      // window.location.reload()
    } else {
      // console.log("Error:", response);
      setLoad(false);
      dispatcher(showSnackbarAction("An error occured, please try again", "error"));
    }
  } catch (error) {
    // console.log("Error:", error);
    setLoad(false);
    dispatcher(showSnackbarAction("An error occured, please try again", "error"));
  }
};

// export const Org_shift_activation = (
//   organization_id: string,
//   is_shift_activation_enable:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         is_shift_activation_enable,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       // console.log("Current Active Status Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const Org_shift_activation = async (
  organization_id: string,
  is_shift_activation_enable: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
      is_shift_activation_enable:is_shift_activation_enable,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      // console.log("Shift Activation Status Updated");
      setLoad(false);
      dispatcher(showSnackbarAction("Settings Update", "success"));
    } else {
      console.log("Error:", response);
      setLoad(false);
      dispatcher(showSnackbarAction("An error occured, please try again", "error"));
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
    dispatcher(showSnackbarAction("An error occured, please try again", "error"));
  }
};

// export const updateAdminSettings = (
//   organization_id: string,
//   otp_verification_before_export:any,
//   otp_verification_mail:any,
//   communication_mail:string,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   const emailVerify = emailValidate(otp_verification_mail);
//   const communicationEmailVerify = emailValidate(communication_mail);
//   if((otp_verification_before_export && !otp_verification_mail) 
//     // || !otp_verification_mail
//   ){
//     setLoad(false);
//     dispatcher(
//       showSnackbarAction("OTP verification Email Is Empty", "error")
//     );
//     return
//   }
//   else if(emailVerify === 'Invalid Email' || communicationEmailVerify === 'Invalid Email'){
//     setLoad(false);
//     dispatcher(
//       showSnackbarAction("Please enter a valid email", "error")
//     );
//     return
//   }
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         otp_verification_before_export:otp_verification_before_export,
//         otp_verification_mail:otp_verification_mail,
//         communication_mail: communication_mail
//       },
//       { merge: true }
//     )
//     .then(() => {
//       // console.log("Current Active Status Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//       window.location.reload();
//     })
//     .catch((e) => {
//       // console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };


export const updateAdminSettings = async (
  organization_id: string,
  otp_verification_before_export: any,
  otp_verification_mail: any,
  communication_mail: string,
  setLoad: any,
  dispatcher: any,
) => {
  const emailVerify = emailValidator(otp_verification_mail);
  const communicationEmailVerify = emailValidator(communication_mail);
  if ((otp_verification_before_export &&!otp_verification_mail)) {
    setLoad(false);
    dispatcher(
      showSnackbarAction("OTP verification Email Is Empty", "error")
    );
    return;
  } else if (emailVerify === 'Invalid Email' || communicationEmailVerify === 'Invalid Email') {
    setLoad(false);
    dispatcher(
      showSnackbarAction("Please enter a valid email", "error")
    );
    return;
  }

  setLoad(true);

  try {

    const bodydata = {
      organization_id:organization_id,
      otp_verification_before_export:otp_verification_before_export,
      otp_verification_mail:otp_verification_mail,
      communication_mail:communication_mail
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      // console.log("Settings Updated");
      setLoad(false);
      dispatcher(
        showSnackbarAction("Settings Update", "success")
      );
      window.location.reload();
    } else {
      console.log("Error:", response);
      setLoad(false);
      dispatcher(
        showSnackbarAction("An error occured, please try again", "error")
      );
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
    dispatcher(
      showSnackbarAction("An error occured, please try again", "error")
    );
  }
};

// export const updateFreezeApproval = (
//   organization_id: string,
//   is_freeze_enabled:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         is_freeze_enabled,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//     })
//     .catch((e) => {
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updateFreezeApproval = async (
  organization_id: string,
  is_freeze_enabled: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
      is_freeze_enabled:is_freeze_enabled,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Settings Update", "success")
      );
    } else {
      // console.log("Error:", response);
      setLoad(false);
      dispatcher(
        showSnackbarAction("An error occured, please try again", "error")
      );
    }
  } catch (error) {
    // console.log("Error:", error);
    setLoad(false);
    dispatcher(
      showSnackbarAction("An error occured, please try again", "error")
    );
  }
};


export const fetchDuplicateContact = (
  organization_id: string,
  contact_number:any,
  setAPIData: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .where("fail_reason","==","Duplicate Contact")
    .where("contact_no","==",contact_number)
    .onSnapshot((apis) => {
      const data: any[] = [];
      if (apis) {
        apis?.docs?.forEach((api) => {
          if (typeof (api?.data().associate_contact) === 'string') {
            let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
            data.push({ ...api.data(), "associate_contact": associateContact });
          }
        });
        setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  return subscriber;
};

// export const updatePastDatepproval = (
//   organization_id: string,
//   is_past_date_approval_enabled:any,
//   setLoad:any,
//   dispatcher:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         is_past_date_approval_enabled,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Update", "success")
//       );
//     })
//     .catch((e) => {
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updatePastDatepproval = async (
  organization_id: string,
  is_past_date_approval_enabled: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
      is_past_date_approval_enabled:is_past_date_approval_enabled,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Settings Update", "success")
      );
    } else {
      console.log("Error:", response);
      setLoad(false);
      dispatcher(
        showSnackbarAction("An error occured, please try again", "error")
      );
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
    dispatcher(
      showSnackbarAction("An error occured, please try again", "error")
    );
  }
};

// export const updatePastDate = (
//   organization_id: string,
//   setLoad:any,
//   dispatcher:any,
//   grace_past_days?:any,
// ) => {
//   firestore
//     .collection("organizations")
//     .doc(organization_id)
//     .set(
//       {
//         grace_past_days
//       },
//       { merge: true }
//     )
//     .then(() => {
//       console.log("Shift Timings Updated");
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("Settings Updated", "success")
//       );
//     })
//     .catch((e) => {
//       console.log("Error:", e);
//       setLoad(false);
//       dispatcher(
//         showSnackbarAction("An error occured, please try again", "error")
//       );
//     });
// };

export const updatePastDate = async (
  organization_id: string,
  setLoad: any,
  dispatcher: any,
  grace_past_days?: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id:organization_id,
      grace_past_days:grace_past_days,
};
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      console.log("Shift Timings Updated");
      setLoad(false);
      dispatcher(
        showSnackbarAction("Settings Updated", "success")
      );
    } else {
      console.log("Error:", response);
      setLoad(false);
      dispatcher(
        showSnackbarAction("An error occured, please try again", "error")
      );
    }
  } catch (error) {
    console.log("Error:", error);
    setLoad(false);
    dispatcher(
      showSnackbarAction("An error occured, please try again", "error")
    );
  }
};


export const updateUserAuthorization = async (
  organization_id: string,
  uid: any[],
  userPermission: any[],
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  authToken: string,
) => {
  try {

    const apiData = {
      organization_id:organization_id,
      uid:uid,
      userPermission:userPermission,
    }

    const res = await axiosHandler.post(`${url}/userAuthorization/update`, apiData, {headers: {'x-access-token': authToken}})
    dispatcher(
      showSnackbarAction(
        res.data.message,
        "success"
      )
    );
    setLoad(false);
    close();
  } catch (error) {
    console.log("updateUserAuthorization",error)
    dispatcher(
      showSnackbarAction(
        "Some Error Occured",
        "error"
      )
    );
    setLoad(false);
    close();
  }
};

export const createApiTokenMongo = async (
  organization_id: string,
  source: string,
  countryCode: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  uid: string,
  authToken: string,
) => {

  try {

    let apiData = {
      organization_id,
      source,
      country_code: countryCode,
      created_by: uid,
      modified_by: uid
    }

    // axios.defaults.headers.common['x-access-token'] = authToken;
    const res = await axiosHandler.post(url + '/apiToken/create', apiData);
    // console.log("api Token Create Res", res);
    dispatcher(
      showSnackbarAction(res.data.message, "success")
    );
    setLoad(false);
    close();

     // Introduce a delay of one second before reloading the window
     setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error: any) {
    dispatcher(
      showSnackbarAction(error.response.data.message, "error")
    );
    setLoad(false);
  }
};

export const updateApiTokenMongo = async (
  source: string,
  countryCode: string,
  id: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  editClose: () => void,
  authToken: string,
  uid: string,
  organization_id: string
) => {
  try {

    let apiData = {
      data: {
        source: source,
        country_code: countryCode,
        modified_by: uid
      },
      id,
      organization_id
    }

    // axios.defaults.headers.common['x-access-token'] = authToken;
    const res = await axiosHandler.put(url + '/apiToken/update', apiData);
    dispatcher(
      showSnackbarAction(res.data.message, "success")
    );
    setLoad(false);
    close();
    editClose();
     // Introduce a delay of one second before reloading the window
     setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error: any) {
    dispatcher(
      showSnackbarAction(error.response.data.message, "error")
    );
    setLoad(false);
  }
};

export const fetchApiTokensMongo = async (
  organization_id: string,
  setAPI: (data: any[]) => void,
  authToken: string,
  setCount: (data: any) => void,
  filters: any,
  sort: any,
  limit: any,
  page: any,
  searchQuery: any,
  setLoad: (load: boolean) => void,
  oldData: any,
  isFinished: any,
  setIsFinished: (load: boolean) => void,
  paginate?: boolean,
  localPageSize?: Number,
  localPage?: Number,
  tempIsFinished?: boolean,
  
) => {
  let initialRequestFailed = false
  try {
   
    if (isFinished === true && tempIsFinished !== false) {
      // alert("I ran");
      return;
    }
    let stringFilters: string = convertToFilterString(filters);
    let records = localPageSize ? localPageSize : limit;
    let apiData: any = {
      organization_id: organization_id,
      sort:  Object.keys(sort).length === 0
      ? { created_at: "-1" }
      : sort,
      filters: stringFilters,
      page: localPage ? localPage : page + 1,
      limit: localPageSize ? localPageSize : limit,
      search: searchQuery
    };
    setLoad(true);
    // axios.defaults.headers.common['x-access-token'] = authToken;
    const res = await axiosHandler.get(url + '/apiToken/fetchAll', {
      params: apiData,
    });
    let apiTokensData = res.data.data.apiTokensData;
    let apiTokensCount = res.data.data.apiTokensCount;
    console.log("data res api tokens", res.data.data, isFinished)
    // if ((page.currentValue < page.previousValue) || oldData.length ) {
    //   return;
    // }
    if (apiTokensData.length < records) {
      setIsFinished(true);
    }

    setCount(apiTokensCount);
    setLoad(false);
    if (paginate && oldData) {
      let newData = [...oldData, ...apiTokensData];
      setAPI(newData);
      setLoad(false);
    } else {
      setAPI(apiTokensData);
      setLoad(false);
    }
    // dispatcher(
    //   showSnackbarAction(res.data.message, "success")
    // );
    // setLoad(false);
    // close();
    // editClose();
  } catch (error: any) {
    // dispatcher(
    //   showSnackbarAction(error.response.data.message, "error")
    // );
    // setLoad(false);
    if (page === 0 && !initialRequestFailed) {
      initialRequestFailed = true; // Set the flag to true after the first failure
      console.warn('Ignoring initial bad request.');
    } else {
      // Handle the error for subsequent requests
      setLoad(false);
      // dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
    }
    setLoad(false);
  }
};

export const fetchApiTokensFilterValues = async (
  organization_id: string,
  authToken: string,
  dispatcher: any,
) => {
  try {

    let apiData = {
      organization_id
    }
    console.log("amaklsls", apiData)
    // axios.defaults.headers.common['x-access-token'] = authToken;
    const res = await axiosHandler.get(url + '/apiToken/filterValues', { params: apiData });
    let data: any = res.data.data[0];
    dispatcher(setFilterObject({ ...data }));
  } catch (error: any) {
    dispatcher(setFilterObject({}))
  }
};


/////////////////////// New Mongo Integration ////////////////////**** 



export const createOrganization = async (
  organization_name: string,
  mobile_number: number,
  email_id: string,
  address: string,
  country: string,
  state: string,
  city: string,
  pincode: number,
  admin_first_name: string,
  admin_last_name: string,
  admin_contact_number: number,
  admin_email_id: string,
  no_of_employees: number,
  business_domain:string,
  history: any,
  dispatcher: any,
  window: any,
  setLoad: (data: boolean) => void,
  cost_per_license: number,
  grace_period: number,
  valid_from: number,
  valid_till: any,
  gst_number: any,
  shift_active_status:any,
  organization_active_status:any,
  authToken:any,
) => {
  
  try {
    let Valid_From = new Date(valid_from)
    let Valid_Till = new Date(valid_till)
    const data = {
      organization_name: organization_name,
      mobile_number: mobile_number,
      email_id: email_id.toLowerCase(),
      address: address,
      country: country,
      state: state,
      city: city,
      pincode: pincode,
      admin_first_name: admin_first_name,
      admin_last_name: admin_last_name,
      admin_contact_number: admin_contact_number,
      admin_email_id: admin_email_id.toLowerCase(),
      no_of_employees: no_of_employees,
      valid_from: Valid_From,
      valid_till: Valid_Till,
      "service_id": "13733",
      "package_id": "",
      "oid": "",
      business_domain:business_domain,
      teams: [],
      designations: [],
      status: "ACTIVE",
      cost_per_license: Number(cost_per_license),
      GSTIN: gst_number,
      current_active_status:shift_active_status,
      organization_active_status:organization_active_status
};
// console.log("data data",data)
// axios.defaults.headers.common['x-access-token'] =
//           authToken
          const response = await axiosHandler.post(url + '/organizations/createOrg', data);

    if (response) {
      // const result =  (await response).json();
      console.log(response);
      dispatcher(
        showSnackbarAction(
          "Organization Created!!",
          "success"
        )
      );
      setLoad(false);
      history.replace("/");
      // Handle success response
    } else {
      console.error('Error creating organization:', response);
      dispatcher(
        showSnackbarAction(
          response,
          "error"
        )
      );
      setLoad(false);
      history.replace("/");
      // Handle error response
    }
  } catch (error:any) {
    console.error('Error creating organization:', error.message);
    dispatcher(
      showSnackbarAction(
        "Try Again !! ",
        "error"
      )
    );
    setLoad(false);
    history.replace("/");
    // Handle error
  }
};


export const editOrganization = async (
  organization_id: string,
  organization_name: string,
  mobile_number: number,
  email_id: string,
  address: string,
  country: string,
  state: string,
  city: string,
  pincode: number,
  admin_first_name: string,
  admin_last_name: string,
  admin_contact_number: number,
  admin_email_id: string,
  contact_person_email: string,
  contact_person_no: number,
  no_of_employees: number,
  auth_id: string,
  // created_at:any,
  business_domain:string,
  history: any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  cost_per_license: number,
  grace_period: number,
  valid_from: any,
  valid_till: any,
  gst_number: any,
  shift_active_status:any,
  organization_active_status:any,
  authToken:any,
) => {
  try {
    let Valid_From = new Date(valid_from)
    let Valid_Till = new Date(valid_till)
    const data = {
       organization_id:organization_id,
        organization_name: organization_name,
        mobile_number: mobile_number,
        email_id: email_id.toLowerCase(),
        address: address,
        country: country,
        state: state,
        city: city,
        // created_at: Created,
        pincode: pincode,
        admin_first_name: admin_first_name,
        admin_last_name: admin_last_name,
        admin_contact_number: admin_contact_number,
        admin_email_id: admin_email_id.toLowerCase(),
        contact_person_email: contact_person_email.toLowerCase(),
        contact_person_no: contact_person_no,
        no_of_employees: no_of_employees,
        business_domain:business_domain,
        teams: [],
        designations: [],
        status: "ACTIVE",
        auth_id: auth_id,
        cost_per_license: Number(cost_per_license),
        grace_period: Number(grace_period),
        valid_from: Valid_From,
        valid_till: Valid_Till,
        GSTIN: gst_number,
        current_active_status:shift_active_status,
        organization_active_status:organization_active_status,
        "service_id": "13733",
        "package_id": "985674",
        "oid": "333333",

};
// console.log("updata data data",data)
// axios.defaults.headers.common['x-access-token'] =
//           authToken
          const response = await axiosHandler.put(url + '/organizations/updateOrg', data);

    if (response) {
      // const result =  (await response).json();
      console.log(response);
      dispatcher(
        showSnackbarAction(
          "Organization Details Updated!!",
          "success"
        )
      );
      setLoad(false);
      history.replace("/");
      // Handle success response
    } else {
      console.error('Error creating organization:', response);
      dispatcher(
        showSnackbarAction(
          response,
          "error"
        )
      );
      setLoad(false);
      history.replace("/");
      // Handle error response
    }
  } catch (error:any) {
    console.error('Error creating organization:', error.message);
    dispatcher(
      showSnackbarAction(
        "Error!! Try Again!!",
        "error"
      )
    );
    setLoad(false);
    history.replace("/");
    // Handle error
  }
};




export const fetchOrganizationData = async(organizationId: any) => {
  // axios.defaults.headers.common["x-access-token"] = authToken;
  const res: any = await axiosHandler.get(
    url + "/organizations/fetchSingleOrganization",
    {
      params: {
        organization_id:organizationId
      },
    }
  );

  return res?.data?.data;
};


export const fetchOrganizations = async (
  setData: (data: any[]) => void,
  authToken:any
) => {
  // axios.defaults.headers.common["x-access-token"] = authToken;
  const res: any = await axiosHandler.get(
    url + "/organizations/fetchAll",
    {
      params: {},

    }
  );
  console.log("authToken",res?.data?.data)
  setData(res?.data?.data);
  return res?.data?.data;
  // return subscriber;
};

export const fetchNewsMongo = async (
  organization_id: string,
  setNews: (data: any[]) => void
) => {
  try {
    const res: any = await axiosHandler.get(
      url + "/news/fetchAll",
      {
        params: { organization_id },

      }
    );
    let news = res.data.data;

    if (news?.news) {
      setNews(news.news);
    } else {
      setNews([]);
    }

    // return subscriber;
  } catch (err) {
    setNews([]);
  }
};

export const createNewsMongo = async (
  organization_id: string,
  areaName: string,
  link: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  try{
    let apiData = {
      organization_id,
      name: areaName,
      link
    }
    const res: any = await axiosHandler.post(
      url + "/news/create",
      apiData
    );
    dispatcher(
      showSnackbarAction("News Link Added !!", "success")
    );
    setLoad(false);
    close();
    window.location.reload();
  }catch(err){
    // console.log("News Error:", err);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const deleteNewsMongo = async (
  organization_id: string,
  filterDataArr: any,
  dispatcher: any,
) => {
  try {
    let apiData = {
      organization_id,
      news: filterDataArr
    }
    const res: any = await axiosHandler.post(
      url + "/news/update",
      apiData
    );
    dispatcher(showSnackbarAction('News Deleted', 'success'));
    window.location.reload();
  } catch (err) {
    // console.log("News Error:", err);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
  }
};

export const updateCallRecordingStatus = async (
  organization_id: string,
  is_call_recording_enabled: any,
  setLoad: any,
  dispatcher: any,
) => {
  setLoad(true);

  try {
    const bodydata = {
      organization_id: organization_id,
      is_call_recording_enabled,
    };
    const response = await axiosHandler.put(url + '/organizations/updateOrg', bodydata);

    if (response) {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Settings Updated", "success")
      );
      dispatcher(setCallRecordingStatus({is_call_recording_enabled}));
    } else {
      // console.log("Error:", response);
      setLoad(false);
      dispatcher(
        showSnackbarAction("An error occured, please try again", "error")
      );
    }
  } catch (error) {
    // console.log("Error:", error);
    setLoad(false);
    dispatcher(
      showSnackbarAction("An error occured, please try again", "error")
    );
  }
};

export const orgHadCallRecordingSubscription = async(organizationId: any) => {
  // axios.defaults.headers.common["x-access-token"] = authToken;
  try{
    const res: any = await axiosHandler.get(
      url + "/packageDetails/orgHadCallRecordingSubscription",
      {
        params: {
          organization_id:organizationId
        },
      }
    );
  
    return res?.data?.data?.orgHadCallRecordingSubscription ? res?.data?.data?.orgHadCallRecordingSubscription : false;
  }catch(error){
    return false;
  }
};