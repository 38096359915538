import React, { useEffect } from "react";
import { BrowserRouter as Router, Route,useHistory  } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import AuthRoute from "./AuthRoute";
import { checkUser, checkUserMongo } from "../Services/auth";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";
import { connect, useDispatch } from "react-redux";
import { updateTheme, setconstants, setRole, updateUserStatus, setAuthToken,setUser} from "../Redux/actions";
import { url, auto_key, iv_key, decryptPAN ,decryptautoLogin} from "../Values/constants";
import { setConstants } from "../Services/resources";
import crypto from "crypto";
import { types } from "util";
import axiosHandler from "../Services/axiosHandler"
import {fetchOrganizationDataMongo,fetchUserDataMongo} from "../Services/auth"

const AppRoutes = ({
  userStatus,
  userRole,
  theme,
  authToken,
  user,
  constants,
}: {
  userStatus: boolean;
  userRole: string;
  theme: any;
  authToken: any;
  user: any;
  constants: any;
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();



  // useEffect(async() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const encryptedToken = decodeURIComponent(urlParams.get('token') || "");
  //   if (encryptedToken) {
        
  //     const obj = JSON.parse(decryptPAN(encryptedToken));

  //     console.log("Rishabh Gupta Login url", obj);

  //     const {device_type,userId,timeStamp}=obj;
  //     console.log("Current Time:", Date.now());
  //     console.log("Token Expiration Time:", timeStamp);

  //     if (timeStamp < Date.now()) {
  //       alert('Token has expired');
  //       // window.history.replaceState(null, '', window.location.pathname)
  //       return;
  //   }

  //   const callData=async(userId:any,device_type:any)=>{
  //     try {

  //       const params={userId:userId,device_type:device_type}
  //   const response = await axiosHandler.post('/auth/getUserOid',  params);
  //       return response.data;
  //     } catch (error) {
  //       return false;
  //     }
  //   }

  //   const data=await callData(userId,device_type);
  //     if(!data){
  //       alert('User not found');
  //       // window.history.replaceState(null, '', window.location.pathname)
  //       return;
  //     }else{
  //       const user=data?.data?.user;
  //         dispatcher(setRole(userData.role));
  //     dispatcher(updateUserStatus(true));
  //     localStorage.setItem('user', JSON.stringify(userData));
  //     dispatcher(setAuthToken({ authToken: token }));
  //    window.history.replaceState(null, '', window.location.pathname)
  //     }
  //   //   const { token, data } = obj;
  //   //   const userData = JSON.parse(data);

      
  //   //   dispatcher(setRole(userData.role));
  //   //   dispatcher(updateUserStatus(true));
  //   //   localStorage.setItem('user', JSON.stringify(userData));
  //   //   dispatcher(setAuthToken({ authToken: token }));
  //   //  window.history.replaceState(null, '', window.location.pathname)
  //   } else {
  //     // Normal code if no token is present
  //     let userData: any = localStorage.getItem("user");
  //     let parsedUser = JSON.parse(userData);

  //     checkUserMongo(dispatcher, parsedUser);
  //     setTheme();
  //   }
  // }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedToken = decodeURIComponent(urlParams.get('token') || "");
        console.log("Rishabh Gupta Login url", encryptedToken);
        if (encryptedToken) {
            // Decrypt the token
            const obj = JSON.parse(decryptautoLogin(encryptedToken));
            console.log("Rishabh Gupta Login url obj", obj);

            const { device_type, userId, timeStamp,contact_id } = obj;
            // console.log("Current Time:", Date.now());
            // console.log("Token Expiration Time:", timeStamp);

            // Check if token has expired
            if (timeStamp < Date.now()) {
              window.history.replaceState(null, '', window.location.pathname);
              dispatcher(updateUserStatus(false));
                alert('Token has expired');
                return;
            }

            // Function to call API and get user data
            const callData = async (userId:any, device_type:any) => {
                try {
                    const params = { userId: userId, device_type: device_type };
                    const response = await axiosHandler.get('/auth/getUserOid', {params:params});
                    return response.data;
                } catch (error) {
                    return false;
                }
            };

            // Fetch the user data using the decrypted userId and device_type
            const data = await callData(userId, device_type);
            // console.log("rishabh data",data)
            if (!data) {
                alert('User not found');
                return;
            }else if (data.success===false){
                alert('User not found');
                return;
            }            
            else {
                const user = data?.data?.user;
                // console.log("toesnjewnfuwenfuw",user,user.token,user.role)
                localStorage.setItem('user', JSON.stringify(user));
                dispatcher(setAuthToken({ authToken: user.token }));


                // Update the app's state with user data
                if (user.role === "superAdmin") {
                  dispatcher(
                    setUser({
                      user_first_name: "Super Admin",
                      user_last_name: "",
                      user_email: user.user_email,
                    })
                  );
                }
                if (user.role === "organization") {
                  fetchOrganizationDataMongo(user, dispatcher);
                } else if (user.role !== "superAdmin") {
                  fetchUserDataMongo(user, dispatcher);
                }
                dispatcher(setRole(user.role));
                dispatcher(updateUserStatus(true));

                if (contact_id) {
                  // window.location.href = `/contactDetailsScreen?contact_id=${contact_id}`;
                  localStorage.setItem('contact_id', contact_id);
                  return;
              }
                
                // Replace URL to remove the token parameter after successful login
                window.history.replaceState(null, '', window.location.pathname);
                return;
            }
        } else {
            // If no token is present, proceed with normal flow
            let userData:any = localStorage.getItem("user");
            let parsedUser = JSON.parse(userData);

            checkUserMongo(dispatcher, parsedUser);
            setTheme();
        }
    };

    fetchData(); // Invoke the async function
}, [authToken]); // Add dependencies



  useEffect(() => {
    if (authToken) {
      setConstants(authToken, dispatcher);
    }

  }, [authToken])

  const setTheme = async () => {
    let isLightMode = await localStorage.getItem("isLightMode") ? localStorage.getItem("isLightMode") : "true";
    if (isLightMode === "true") {
      dispatcher(updateTheme(true));
    } else {
      dispatcher(updateTheme(false));
    }
  };

  return (
    <Router>
      {userStatus !== undefined && (
        <>
          {userStatus === false && <Route component={AuthRoute} />}
          {userStatus === true && <Route component={HomeRoute} />}
        </>
      )}
      <Route path="/resetPassword" component={PasswordReset} />
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
    user: state.user.data,
    constants: state.constants.data,
  };
};

export default connect(mapStateToProps)(AppRoutes);
