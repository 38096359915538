import React, { useEffect, useState } from "react";
import styles from "./TextInput.module.css";

type props = {
  title: string;
  children?: any;
  style?: any;
  validator?: (value: string) => string;
  length?: boolean;
  disabled?: boolean;
  value?: string;
  type?: string;
  minValue?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Add onChange prop
  inputPattern?: any;
};

const TextInput = React.forwardRef(
  (
    { title, children, style, validator, length, disabled, value, type, minValue, onKeyDown, onChange,inputPattern }: props,
    ref: any
  ) => {
    const [errorMsg, setErrorMsg] = useState("");
    const [val, setVal] = useState(value || ""); // Initialize with value prop or empty string

    useEffect(() => {
      if (value !== undefined) setVal(value); // Sync internal state with value prop
    }, [value]);

    return (
      <>
        <div className={styles.input}>
          <input
            style={{
              fontSize: "14px",
              backgroundColor: disabled ? "#f2f2f2" : "#fff",
              color: disabled ? "#b1adb7" : "black",
              ...style,
            }}
            className="form-control"
            type={type ? type : "text"}
            name="name"
            placeholder={title}
            ref={ref}
            onBlur={(event) => {
              if (validator) {
                setErrorMsg(validator(ref.current.value));
              }
            }}
            maxLength={length === true ? 10 : undefined}
            readOnly={disabled}
            onChange={(e) => {
              if(inputPattern){
                if (inputPattern.test(e.target.value)) { // Check if the value matches the regex
                  setVal(e.target.value);
                  if (onChange) onChange(e); // Call onChange prop if provided
                }
              }else{
                setVal(e.target.value);
                if (onChange) onChange(e); // Call onChange prop if provided
              }
            }}
            value={val}
            min={minValue ? minValue : undefined}
            autoComplete="off"
            onKeyDown={onKeyDown}
            // pattern={inputPattern}
          />
        </div>
        {errorMsg.length !== 0 && <p className={styles.error}>{errorMsg}</p>}
      </>
    );
  }
);

export default TextInput;
